@import './../../styles/variable.scss';

.sideBar {
  :global {
    .navbar-nav {
      padding: 10px 10px 0px 10px;
      padding-left: 15px;
    }

    .card {
      background-color: $bg-sidebar;
    }

    .card-body {
      padding: 0px;
    }
  }

  .groupTitle {
    display: flex;
    background-color: $bg-white;
    padding: 11px 0px 6px 15px;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }

    .titleSideBar {
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      color: $color-blueGray;
    }

    &:first-child {
      border-radius: 5px 5px 0px 0px;
    }
  }

  .link {
    font-weight: 500;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $color-blueGray;
    display: inline-block;
    width: 100%;

    .text {
      padding-top: 15px;
      padding: 10px 10px 5px 10px;
      display: inline-block;
    }

    &:hover,
    &.isActive {
      background-color: $color-yellow1;
      color: $color-yellow;
      border-radius: 5px;
      font-weight: 500;
    }
  }

  .ctCard {
    border: none;
  }
}
