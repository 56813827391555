@import './../../../../styles/variable.scss';

ul {
  list-style: none;
  padding-left: 0;
}

:global {
  .MuiMenuItem-root {
    svg {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
  }
  .import-contract {
    .MuiPaper-root {
      max-width: 500px !important;
      .modal-body {
        max-height: 400px;
        overflow: scroll;
        .import {
          margin-top: 0;
          .labelImport {
            width: -webkit-fill-available;
            position: relative;
            top: 0;
            left: 0;

            .imgImport {
              width: -webkit-fill-available;
              height: 100px;
              cursor: pointer;
            }
          }
        }
        .progress-percent {
          position: absolute;
          right: 6%;
          overflow: visible;
          height: 0px;
        }
        .error {
          background: $bg-errorImport;
          border-radius: 5px;
          padding: 10px 35px 10px 15px;
          margin-top: 10px;
        }
        .fileInvalid {
          height: 15px;
          margin-right: 5px;
        }
        .inforImport {
          color: #333333;
          font-weight: 500;
        }
        .progress-import {
          width: 100% !important;
          margin-right: 0 !important;
        }
        .infor {
          position: absolute;
          z-index: 1;
          color: $color-grey1;
          font-size: 14px;
        }
        .iconImport {
          height: 20px;
          width: 25px;
          left: 48%;
          top: 13px;
        }
        .notifi {
          left: 38%;
          top: 40px;
        }
        .format {
          left: 39%;
          top: 60px;
        }
        .download-template {
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-decoration-line: underline;
          color: #0085ff;
          margin-bottom: 20px;
          cursor: pointer;
        }
      }
      .modal-body::-webkit-scrollbar {
        width: 2px;
      }
      .modal-body.thin::-webkit-scrollbar {
        width: 2px;
      }
      .modal-body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $bg-yellow1;
      }
    }
  }
}

.export {
  margin-left: 10px;
  width: 137px;
}

.paddingLeft {
  padding-left: 20px;
}

.floatRight {
  float: right;
}

.flex {
  display: flex;
}

.borderTop {
  border-top: 1px solid $color-wheat;
}

.button {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  height: 40px;
  margin-top: -15px;
}

.profileBox {
  margin-top: 20px;
  .assetHeader {
    color: $color-black;
    line-height: 2rem;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: auto;
  }

  :global {
    .MuiTableCell-root {
      white-space: normal !important;
      word-wrap: break-word !important;
      padding: 16px 8px;
    }
    .css-15wwp11-MuiTableHead-root {
      .MuiTableCell-root {
        color: $color-titleBody;
        font-weight: 500;
      }
    }
  }

  .assetHeaderRow {
    background-color: $color-headTable !important;
    color: $color-titleBody;
  }

  .tableRow {
    cursor: pointer;
  }

  :global {
    .MuiTableCell-root {
      text-align: left;
    }
  }

  .assetContent {
    color: $color-black;
    line-height: 2rem;
    font-size: 0.9rem;
    font-weight: bold;
    td {
      cursor: pointer;
    }
  }

  .assetHeader tr {
    background-color: $color-headTable !important;
  }

  tr:nth-child(odd) {
    background-color: $bg-white;
  }

  tr:nth-child(even) {
    background-color: $bg-tableStripped;
  }
}

.profileBox {
  padding: 0;
  box-shadow: 0px 5px 25px $color-black2;
  border-radius: 5px;

  :global {
    .css-o9ey65{
      position: relative;
      .MuiTablePagination-displayedRows.css-1chpzqh{
          position: absolute;
          left: 17px;
        }
      
      .MuiTablePagination-actions {
        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
          color: $color-menuActive;
          font-size: 30px;
        }
      }
    }
  }

  table {
    width: 100%;
    margin: 0;
    table-layout: fixed;

    .profileContentInfo {
      word-wrap: break-word;
      font-weight: normal;
    }

    .thead {
      background-color: $color-headTable;

      .profileContentInfo {
        color: $color-titleBody;
      }
    }

    tr {
      font-weight: 500;
      color: $color-charcoal;
    }
  }
}

.boxWaiting {
  margin: 19px 0 0 23px;
  height: 72px;
  background: $bg-errorImport;
  border: 1px solid $color-borderError;
  box-sizing: border-box;
  cursor: pointer;

  box-shadow: 0px 5px 25px $color-black2;
  border-radius: 5px;
  .boxWaitingContent {
    padding: 18px 0 15px 20px;
    display: flex;
    img {
      width: 23px;
      height: 23px;
      margin-right: 10px;
    }
    .boxWaitingText {
      color: $color-error;
      .waitingText {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
      .waitingNumber {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
      }
    }
  }
}

.boxFilter {
  background: $bg-tooltip;
  margin: 19px 0 0 23px;
  height: auto;
  padding: 15px;

  border: 1px solid $color-wheat;
  box-sizing: border-box;

  box-shadow: 0px 5px 25px $color-black2;
  border-radius: 5px;

  .exampleSelectMulti {
    appearance: none;
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid $color-inputColor;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: $color-white;
    justify-content: center;
    align-items: flex-start;
    padding: 5px 13px;
    height: auto;
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }
  }

  ::-webkit-input-placeholder {
    color: #8d8d8d;
  }

  .paddingElementFilter {
    padding-bottom: 20px;
  }

  .paddingElementTop {
    padding-top: 20px;
  }

  .marginElementBottom {
    margin-bottom: 30px;
  }

  .borderTop {
    border-top: 1px solid $color-borderTable;
  }

  .text {
    color: $color-titleBody;
    font-weight: 500px;
    font-size: 16px;
  }

  .division {
    cursor: pointer;
    position: relative;
    width: 100%;

    .total {
      position: absolute;
      right: 0px;
      width: auto;
      padding: 6px;
      background: $color-headTable;
      color: $color-titleBody;
      border-radius: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }
  }

  li {
    padding-bottom: 25px;
  }

  .reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    margin-top: 10px;
    height: 36px;
    background: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .imgReset {
      margin: '0 10px 0 0';
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }
}
.linkRequest {
  color: #333333;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
}
.email {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 0 0;
  color: $color-email;
}
.colorWaiting {
  color: $color-blue1;
}
.colorDeny {
  color: $bg-menuNumberInfo;
}
.colorAccept {
  color: $color-active;
}

.infoDetail {
  display: flex;
  padding: 23px 20px 20px 20px;
  background-color: $color-headTable;
  .statusInfo {
    line-height: 20px;
  }
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .info {
    width: 100%;
  }
  .linkRequest {
    line-height: 18px !important;
  }
  .email {
    line-height: 18px !important;
  }
  .buttonAcceptHr {
    margin-top: 5px;
    .hrAccept {
      width: 170px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
.infoProfile {
  padding: 15px 25px;
  .infoProfileElement {
    padding: 20px 0;
    margin-right: 20px;
    border-bottom: 1px solid $color-borderTable;
    .infoProfileLabel {
      color: $color-titleBody;
      font-weight: 500;
    }
    .infoProfileContent {
      color: $color-charcoal;
      font-weight: normal;
    }
  }
}

.width60 {
  width: 60px;
}

.modal {
  :global {
    .modal-body {
      padding: 0;
    }
  }
  @media (min-width: 1024px) {
    :global {
      .MuiPaper-root {
        min-width: 673px;
        margin: 1.75rem auto;
      }
    }
  }
}
