$bg-body: #f9fbfc;
$bg-menu: #222433;
$bg-menuActive: rgba(255, 255, 255, 0.15);
$bg-menuNumberInfo: #e74c3c;
$bg-rowTable: #f9f9f9;
$bg-modal: #222433;
$bg-nav: #8d8d8d;
$bg-breadcrumb: #f7fafc;
$bg-white: white;
$bg-thTable: rgba(255, 219, 169, 0.4);
$bg-thTable1: #f3f7fb;
$bg-sidebar: #f2f2f2;
$bg-tooltip: #ffffff;
$bg-errorImport: #ffe8ec;
$bg-magnolia: #ebddff;
$bg-pink1: #ffc6c6;
$bg-magnolia1: #f1e8ff;
$bg-blue-violet: #8835ff;
$bg-boder-top: #eef0f1;

$color-label-time-keeping: #33C2FF;
$color-border-dayy-off:#B8E8FD;
$color-late: #ff9900;
$color-menu: #f9f9f9;
$color-menuActive: #fea628;
$color-navActive: #223354;
$color-nav: #8d8d8d;
$color-titleBody: #223354;
$color-truncate: #8d8d8d;
$color-titleExport: #333333;
$color-exportActive: rgba(254, 166, 40, 0.4);
$color-buttonSubmit: #fea628;
$color-buttonSubmitActive: #fbc72b;
$color-yellow2: #ff9900;
$color-yellow3: #ffa31a;
$color-yellow4: #ffdba959;
$color-titleModal: #222433;
$color-perPage: #525f7f;
$color-table: #ffffff;
$color-borderTable: #e7e7e7;
$color-headTable: #e9ebee;
$color-childTable: #f9f9f9;
$color-totalEmployee: #ffdba9;
$color-totalNotCompleted: #f3f7fb;
$color-inforPersionnel: #666666;
$color-inputColor: #e7e7e7;
$color-tableStripped: #f2f2f2;
$color-boxCareerActive: #d5f5e3;
$color-managerNameBox: #0085ff;
$color-dataCV: #c4c4c4;
$color-boderRequset: #e6e5fd;
$color-button: #f9fbfc;
$color-effort: #0085ff;
$color-active: #2ecc71;
$color-active1: #2f852d;
$color-email: #919191;
$color-reacTab: #fea62866;
$color-borderError: #ffbfbf;
$color-error: #ee0000;
$color-input-modal: #7e8185;
$color-status-open: #2D99FF;

$bg-plum: #f3f7fb;
$bg-salmon: #e9ebee;
$bg-yellow: rgba(254, 166, 40, 0.1);
$bg-yellow1: #fea628;
$bg-yellow3: #f9e2c0;
$bg-yellow4: #fff6e8;
$bg-yellow1h: rgba(254, 166, 40, 0.8);
$bg-white: white;
$bg-yellow2: #fff8ee;
$bg-tableStripped: #f2f2f2;
$bg-blue1: #ebf9ff;
$bg-row-even: #f9f9f9;
$bg-blue2: #223354;

$color-black: black;
$color-black1: #141414;
$color-black2: rgba(34, 51, 84, 0.15);
$color-charcoal: #333333;
$color-pink: #ff748b;
$color-pink1: #fbdfea;
$color-blue: #2d5197;
$color-blue1: #0057ff;
$color-blue2: #76caf9;
$color-blue3: #e0f0fa;
$color-blue4: #33c2ff;
$color-blueGray: #526487;
$color-yellow: #fea628;
$color-yellow1: #fff8ee;
$color-navy: #223354;
$color-grey: #ebebeb;
$color-grey1: #bdbdbd;
$color-white: white;
$color-white1: #fcfcfc;
$color-green: #66d997;
$color-green1: #bbfdb9;
$color-red: #e74c3c;
$color-silver: #8d8d8d;
$color-wheat: #e7e7e7;
$color-box-shadow: #22335426;
$color-boder-table: #dee2e6;
$color-switch-base: #6200ee;
$color-muiswitch-track: #6200ee61;
$color-boder-table: #dee2e6;
$color-status-removed: #979797;

$color-border-input: rgba(0, 0, 0, 0.23);

$color-active-selected: rgba(254, 166, 40, 0.4);
$bg-scrollbar-track-notification: #dfdfdf;
$bg-scrollbar-notification: #8f8f8f;
$bg-box-home: #5c3b3b1a;

$bgTimeLate: #e74c3c;
$bgTimeLateUnApprove: #a4a4a4;
$bgTimeOT: #3db2ff;
$color-status-good: #2ECC71;
$color-status-missing: #E74C3C;
$color-status-underWarranty : #AD00FF;
$color-status-broken : #FEA628;
$color-status-waitForLiquidation : #0057FF;
$color-status-liquidation: #FA9AAA;
$color-status-waitForApprove: #9AE3FA;
$color-status-cancel: #666666;
$color-status-open: #48CAE4;
$color-deny: #FF5E5E;
$color-rework: #FF820E;
