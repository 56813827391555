@import './../../../../styles/variable';

.master-data-category-position {
  .input-search {
    width: 280px;
  }

  .table-list {
    margin-bottom: 100px;
  }
}
