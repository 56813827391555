@import './../../styles/variable.scss';

.modalAlert {
  z-index: 9999 !important;
  :global {
    .modal-footer {
      padding-top: 0px !important;
      border-top: none;
    }
    .modal-body {
      padding-top: 2rem;
    }
    button {
      color: $color-yellow;
      text-transform: uppercase;
      font-size: 14px;
      border: 1px solid transparent;
      box-shadow: none;
      background: inherit;
      outline: 0;

      &:hover {
        color: $color-yellow;
        background-color: $bg-white;
        border: 1px solid $color-yellow;
      }
      &:focus,
      &:active {
        color: $color-white !important;
        background-color: $bg-yellow1 !important;
        border: 1px solid $color-yellow !important;
        outline: 0;
      }
    }
  }
}

@media (min-width: 576px) {
  .modalAlert {
    :global {
      .modal-body {
        min-width: 380px;
      }
    }
  }
}
