@import "styles/variable.scss";

.customButtonEdit {
  height: auto !important;
}
.modalRecall {
  :global {
    label.Mui-focused {
      color: $color-green !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: $color-green !important;
    }
  }
  .fieldReason:hover {
    label {
      color: $color-green !important;
    }
    :global {
      .MuiOutlinedInput-notchedOutline {
        border-color: $color-green !important;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: $color-green !important;
      }
    }
  }
}
