@import "styles/variable";

.master-data-edit-role {
  a {
    text-decoration: none !important;
  }
  .bread-crumbs {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    .page-current {
      color: $color-navy;
      font-weight: 500;
      font-size: 14px;
    }
    a {
      color: $color-silver;
    }
  }
  img.radio {
    width: 20px;
    cursor: pointer;
  }
  .filter-custom {
    margin-top: 50px;
    display: flex;
    span {
      cursor: pointer;
      margin-left: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $color-titleExport;
    }
    span.create-filter {
      margin-right: 100px;
    }
  }
  .nav-role {
    margin-top: 30px;
  }
}
.master-data-detail-user {
  .search-add-member {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  .table-list {
    margin-top: 12px;
    box-shadow: 0px 5px 25px $color-box-shadow;
    color: $color-black;
    background: $color-table;
    box-sizing: border-box !important;
    border-radius: 5px;
    border: 2px solid $color-boder-table;
    max-height: 600px;
    tr.header {
      position: sticky;
      top: 0;
      height: 40px;
      z-index: 1;
    }
    th {
      vertical-align: bottom;
      border-bottom: 2px solid $color-boder-table;
    }
    td {
      padding: 16px;
      border-bottom: none;
      .email {
        color: #8d8d8d;
        font-size: 12px;
        line-height: 20px;
      }
    }
    td.error-no-data {
      text-align: center;
      color: $color-red;
    }
    .css-1nr2wod {
      color: $color-switch-base !important;
    }
    .css-1nr2wod.Mui-checked + .MuiSwitch-track {
      background-color: $color-muiswitch-track !important;
    }
  }
  .table-list::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .table-list.thin::-webkit-scrollbar {
    width: 2px;
  }
  .table-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-boder-table;
  }
  .table-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $bg-yellow1;
  }
  .table-list::-webkit-scrollbar-track-piece:start {
    margin-top: 40px;
    background: $bg-white;
  }
}
.master-data-detail-role {
  .title-role {
    color: $color-navActive;
    margin: 20px 0 0 0;
  }
  .save-role {
    align-self: flex-end;
    justify-content: flex-end;
    margin-right: 10px;
  }
  .icon-loading {
    font-size: 14px;
    margin-left: 10px;
  }
  .ctButton {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $color-white;
    background-color: $bg-yellow1;
    border-radius: 5px;
    padding: 7px 32px;
    box-shadow: none;
    border: 1px solid $color-yellow1;
    white-space: nowrap;

    &:active,
    &:focus {
      background-color: $bg-yellow1 !important;
      border: 1px solid $color-yellow1 !important;
      color: $color-white !important;
      box-shadow: none;
    }

    &:hover {
      background-color: $bg-white;
      color: $color-yellow;
      border: 1px solid $color-yellow;
    }

    img {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
    :global {
      svg {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
}
