@import 'styles/variable.scss';

.table {
  border: none;
  border-radius: 5px;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

  :global(.ant-table-thead) tr {
    background-color: #E9EBEE;

    th {
      padding: 13.5px;
      color: #223354;
      background-color: transparent;
    }
  }

  :global(.ant-table-tbody) tr {
    &:nth-child(2n) {
      background-color: #F9F9F9;
    }
  }

  :global(.ant-table-cell) {
    &:global(.ant-table-cell-fix-right) {
      background-color: transparent;
    }
    &::before {
      display: none;
    }
  }
}

.total {
  position: absolute;
  right: 0;
  padding: 6px;
  background: $color-headTable;
  color: $color-blueGray;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.groupButton {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  height: 40px;

  .ctButton {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $color-white;
    background-color: #fea628;
    border-radius: 5px;
    padding: 7px 32px;
    box-shadow: none;
    border: 1px solid #fff8ee;
    white-space: nowrap;
    margin-left: 10px;
    height: 100%;

    svg {
      font-size: 20px;
      margin-right: 5px;
    }

    &:hover,
    &:active {
      background-color: $color-white !important;
      color: #fea628 !important;
      border: 1px solid #fea628 !important;
    }
  }
}

.boxFilter {
  background: $bg-tooltip;
  margin: 0 0 0 23px;
  height: auto;
  padding: 15px;
  border: 1px solid $color-wheat;
  box-sizing: border-box;
  box-shadow: 0px 5px 25px $color-black2;
  border-radius: 5px;

  .paddingElementTop {
    padding-top: 15px;
  }

  .selectStatus {
    appearance: none;
    margin-bottom: 15px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid $color-inputColor;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: $color-white !important;
    color: $color-input-modal;
  }

  .marginElementBottom {
    margin-bottom: 30px;
  }

  .borderTop {
    border-top: 1px solid $color-borderTable;
  }

  .text {
    color: $color-navy;
    font-weight: 500;
    font-size: 16px;
  }

  .reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    margin-top: 10px;
    height: 36px;
    background: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .imgReset {
      margin: '0 10px 0 0';
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }

  :global {
    .from-date {
      margin-bottom: 20px !important;
    }
  }
}
