@import 'styles/variable.scss';

.itemTitle {
  font-size: 16px;
  color: #223354;
}

.space {
  margin-top: 10px;
}

.error {
  margin-top: 28px !important;
}

.iconDelete {
  margin-top: 10px;
  padding: 0 3px;
  color: $color-truncate;
  border: 1px solid #E7E7E7;
  float: left;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-bottom: 5px;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }

  svg {
    margin-bottom: 1px;
    font-size: 18px;
  }
}

.addItems {
  border: 1px dashed $color-wheat;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
  padding-left: 14px;

  &:hover {
    cursor: pointer;
  }

  .icon {
    width: 50px;
    padding: 12px;
  }

  .textAdd {
    color: #8D8D8D;
  }
}

.pointerEvents {
  pointer-events: none;
}

.salarySection {
  margin-top: 38px;
}
