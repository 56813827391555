@import './../../../styles/variable.scss';

.modalCreateContract {
  :global {
    .MuiGrid-item {
      margin-top: 4px;
    }
  }
  .footerModal {
    width: 100%;
    padding-left: 15px;
    margin-bottom: -15px;
    :global {
      .MuiGrid-item {
        margin-top: 0px;
        padding-top: 15px;
      }
    }
  }
  .colorCheckBox {
    :global {
      .MuiTypography-root {
        font-size: 14px;
        color: $color-charcoal;
      }
    }
  }
  .onboard {
    margin-top: -20px;
  }
  .notification {
    margin: 0 0 0 16px;
    color: rgba(0, 0, 0, 0.38);
  }
}
