@import './../../../../../styles/variable';

.master-certificate {
  .input-search {
    width: 280px;
  }

  .select {
    height: 35px;
    border-width: 0 !important;
  }

  .manager-certificate {
    .makeStyles-inputLabelSelectField-2.MuiFormLabel-root {
      top: -8px !important;
    }
  }
}
