.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.w-full {
  width: 100%;
}

// css
.title {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #223354;
  font-weight: 500;
  .btn-add-member{
    background-color: #F9FBFC;
    width: 25px !important;
    height: 25px !important;
  }
}
.detail-title{
 border-bottom: solid 1px #E7E7E7;
 padding-bottom: 13px;
}
.button {
  border-radius: 5px !important;
  border: none !important;
  outline: none !important;
  font-weight: 500 !important;
  box-shadow: none !important;
  background-color: transparent;
}

.button::after {
  display: none;
}

.addMember {
  display: flex !important;
  align-items: center;
  gap: 10px;
  background: transparent !important;
  color: #fea628 !important;
  text-shadow: none !important;
  margin-left: auto;
  margin-right: auto;
}

.edit-course,
.delete-course {
  background: transparent !important;
  margin-left: 8px;
}
.course-info{
  border-radius: 10px !important;
  border: 1px solid #e7e7e7 !important;
  background: #fff !important;
  box-shadow: 0px 5px 25px 0px #22335426 !important;
  color: #223354 !important;
  .ant-card-meta-title{
  font-size: 14px;  
  color: #223354; 
  }
  .btn-edit-course{
    background-color: #F9FBFC;
    width: 25px !important;
    height: 25px !important;
  }
  .ant-divider-horizontal {
    margin: 10px 0 !important;
}
.btn-edit-course{
  position: absolute;
  right:13px;
  top:18px;
}
}
.card {
  border-radius: 10px !important;
  border: 1px solid #e7e7e7 !important;
  background: #fff !important;
  box-shadow: 0px 5px 25px 0px #22335426 !important;
  

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  .ant-card-head {
    border: none !important;
  }

  .addProgram {
    height: 40px !important;
    display: flex !important;
    align-items: center;
    gap: 10px;
    padding: 8px 16px !important;
    background: #fea628 !important;
  }
  .ant-card-body{
    padding: 10px 20px 20px;
    
    .ant-table-thead {
      .ant-table-cell::before {
        display: none;
      }

      .ant-table-cell {
        color: #223354;
      }
    }
  }
  .ant-table {
    border: 1px solid #e7e7e7;
    border-bottom: none;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 5px 20px 0px rgba(59, 59, 59, 0.1);
    overflow: hidden;

    .ant-table-thead > tr > th {
      background: #e9ebee;
    }

    .ant-table-tbody {
      td.ant-table-cell {
        color: #333;
      }

      .ant-table-row:nth-child(even) .ant-table-cell {
        background: #f9f9f9;
      }

      tr:hover > td {
        background: rgb(235, 235, 235) !important;
        cursor: pointer;
      }
    }
  }
}

// pagination
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  margin: 0;

  span {
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
  }

  .color {
    background-color: 'red';
  }

  .ant-select-selector {
    box-shadow: none !important;
    border: none !important;
    background: transparent !important;
  }

  button:disabled {
    border: none;
  }
}

.cursor-no-drop {
  cursor: no-drop;
}

.opacity-50 {
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer;
}

.ml-3 {
  margin-left: 0.75rem /* 12px */;
}

.gap-4 {
  gap: 20px;
}
.nav-bar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  .breadcrumb{
    background-color: transparent;
    padding: 0;
    margin: 0;
    .breadcrumb-item :first-child{
      color:#8D8D8D
    }
    .breadcrumb-item+.breadcrumb-item::before{
      color:#8D8D8D
    }
    .breadcrumb-item+.breadcrumb-item{
      font-weight: 500;
      color: #223354;
    }
  }
  .export {
    font-weight: 500;
    min-width: 150px;
    text-shadow: none !important;
    height: 40px;
    background-color: #fea628;
    color: #fff;
    line-height: 21px;
  }
  .export,
  .export:focus {
    background-color: #fea628;
    color: #fff;
    border: 1px solid #fea628 !important;
    outline: none;
    box-shadow: none;
  }
  .export:hover {
    background-color: #ffbd52 !important;
    border: 1px solid #ffbd52 !important;
    color: #fff;
  }


}
.materialStyle {
  .ant-form-item {
    margin-bottom: 12px;
    flex: 1;

    .ant-form-item-label {
      z-index: 2;
      height: 10px;
      overflow: visible;

      label {
        top: calc(10px + (50px - 22px) / 2);
        left: 6px;
        transform-origin: top left;
        transition: all 0.2s;
        padding: 0 0.4rem;
        background-color: white;
        color: #8D8D8D;
        height: 21px;

        .ant-form-item-required::before {
          display: none;
        }

        .ant-form-item-required::after {
          display: inline-block;
          visibility: visible;
          margin: 0;
          margin-inline-start: 3px;
          color: inherit;
          content: '*';
        }
      }
    }

    .ant-select {
      > span.ant-select-clear {
        margin-right: 4px;
      }

      .ant-select-dropdown {
        z-index: 2;
      }
    }

    :has(input:focus) .ant-form-item-label label,
    :has(input[value]:not([value=''])) .ant-form-item-label label,
    :has(textarea:focus) .ant-form-item-label label,
    :has(textarea:not(:empty)) .ant-form-item-label label,
    :has(.ant-select-selection-item) .ant-form-item-label label {
      transform: translateY(-25px) scale(0.75);
    }

    :has(.ant-input-affix-wrapper-focused, 
    .ant-select-focused, .ant-input:focus, 
    .ant-input-number-input:focus, 
    .ant-input-affix-wrapper-focused,
    .ant-picker-focused){
      .ant-form-item-label label {
        color: #66d997;
      }
    }

    @media (max-width: 575px) {
      .ant-form-item-label {
        flex: none;
      }
    }
  }

  .ant-form-item-has-error {
    :has(.ant-input-affix-wrapper-focused, 
    .ant-select-focused, .ant-input:focus, 
    .ant-input-number-input:focus, 
    .ant-input-affix-wrapper-focused,
    .ant-picker-focused,
    .ant-form-item-required){
      .ant-form-item-label label {
        color: #ff4d4f ;
      }
    }

    .ant-picker-focused {
      border-color: #ff4d4f;
    }
  }
}

.custom-modal {
  .ant-modal-content {
    border-radius: 5px;
    overflow: hidden;

    .ant-modal-close {
      border: 1px solid #223354;
      border-radius: 50%;
      width: 30;
      aspect-ratio: 1;
      top: 12px;
      right: 12px;

      .ant-modal-close-x {
        width: unset;
        height: unset;
        line-height: unset;
      }
    }

    .ant-modal-header {
      padding: 0 20px;
      background: #222433;
      height: 50px;
      display: flex;
      align-items: center;

      .ant-modal-title {
        color: #fff;
      }
    }

    .ant-modal-body {
      padding: 20px !important;
    }

    .ant-modal-footer {
      border: none;
      display: flex;
      gap: 20px;
      padding: 0 20px 20px 20px;

      button {
        flex: 1;
        min-height: 40px;
        margin: 0 !important;
        border-radius: 5px;
        border: none;
        font-weight: 500;
      }

      button:focus,
      button:hover {
        border: none;
        opacity: 0.9;
      }

      .btn-cancel {
        background-color: #fff8ee;
        color: #fea628;

        :hover,
        :focus {
          color: #fea628;
        }
      }

      .btn-ok {
        background-color: #fea628;
      }
    }
  }
}

.form {
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  .contain-form {
    gap: 8px;
    flex-flow: row wrap;
    display: flex;

    .ant-form-item {
      outline: none;
    }
  }

  .ant-input {
    border-radius: 5px !important;
    height: 50px;
  }

  .ant-input-affix-wrapper {
    border-radius: 5px !important;
    height: 50px !important;

    .ant-input {
      height: 35px;
    }
  }

  .ant-input:focus,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
    border-color: #66d997;
  }

  .ant-input-number {
    width: 100%;
    height: 50px !important;
    border-radius: 5px !important;

    .ant-input-number-input {
      height: 48px !important;
    }
  }

  .ant-select-selector {
    height: 50px !important;
    border-radius: 5px !important;
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }

    .ant-select-selection-search-input {
      height: 35px;
    }

    .ant-select-selection-item {
      line-height: 48px !important;
    }

    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        font-size: 14px;
        height: 25px;
        border: none;
        line-height: 23px !important;
        border-radius: 25px;
        background-color: #e5e6ec;
        color: #333333;

        .ant-select-selection-item-remove {
          font-size: 18px;
          color: rgba($color: #000000, $alpha: 0.6);

          .anticon-close {
            border-radius: 50%;
            background-color: #5c5c5e;
            color: #e5e6ec;
            font-size: 10px;
            padding: 2px;
          }
        }
      }
    }
  }

  .ant-picker {
    width: 100%;
    border-radius: 5px !important;
    height: 50px !important;
  }

  .ant-picker:hover,
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #66d997;
    box-shadow: none !important;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #66d997;
    box-shadow: none !important;
  }

  .ant-input-number:hover, .ant-input:hover {
    border-color: #66d997;
  }

  .ant-input-number-focused, .ant-picker-focused {
    border-color: #66d997;
    box-shadow: none !important;
  }
  
  .ant-input-lg, .ant-select-lg, .ant-picker-large .ant-picker-input > input {
    font-size: 15px;
  }

  .ant-form-item-has-error {
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-picker:hover {
      border-color: #ff4d4f;
    }
  }

  .ant-form-item:has(.ant-picker-disabled, .ant-select-disabled, .ant-input-number-disabled, .ant-input-disabled) {
    label {
      background: #f5f5f5;
    }
  }
}

// pick month
.ant-picker-month-panel {
  .ant-picker-cell .ant-picker-cell-inner {
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
  }

  .ant-picker-cell::before {
    height: 40px;
  }
}

// option
.option {
  display: flex;
  align-items: flex-end;
  gap: 8px;

  img {
    border-radius: 50%;
    overflow: hidden;
  }
}

// filter
.filter-form {
  display: flex;
  align-items: center;
  gap: 16px;

  .ant-form-item {
    flex: 1;
  }

  .program-name label {
    left: 30px !important;
  }
}

.filter-button {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;

  .refresh,
  .search {
    font-weight: 500;
    min-width: 150px;
    text-shadow: none !important;
    height: 40px;
  }

  .refresh,
  .refresh:focus {
    background: transparent !important;
    color: #fea628;
    border: 1px solid #fea628 !important;
    outline: none;
    box-shadow: none;
  }

  .refresh:hover {
    background-color: #fff7e9 !important;
    color: #fea628 !important;
  }

  .search,
  .search:focus {
    background-color: #fea628;
    color: #fff;
    border: 1px solid #fea628 !important;
    outline: none;
    box-shadow: none;
  }

  .search:hover {
    background-color: #ffbd52 !important;
    border: 1px solid #ffbd52 !important;
    color: #fff;
  }
}

.fieldDisable {
  .ant-form-item-label label {
    background: linear-gradient(to bottom, transparent 0%, transparent 42%, #f5f5f5 42%, #f5f5f5 100%);
    background-color: transparent !important;
  }

  .ant-select-arrow {
    visibility: hidden;
  }
}

.refund-full {
  color: #0085ff;
}
.refund-percent {
  color: #8444d4;
}

// dialog
.dialog {
  overflow: hidden;
  font-family: 'Roboto', sans-serif;

  .ant-modal-body {
    padding: 20px !important;
  }

  .ant-modal-confirm-content {
    width: -webkit-fill-available;
  }

  .ant-modal-content {
    border-radius: 4px !important;
  }

  .ant-modal-confirm-paragraph {
    max-width: 100% !important;
  }

  .btn {
    font-size: 14px;
    font-weight: 500;
    color: #8d8d8d !important;
    min-width: 0;
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .btn__ok {
    color: #fea628 !important;
  }
}

.member-info{
  gap: 8px;
  padding-bottom: 15px;
  word-break: break-all;
  justify-content:space-between;
}
.avatar-container {
  position: relative;
  display: inline-block;
}
.delete-icon {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
.avatar-container:hover::before {
  content: "";
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  width: 63px;
  height: 63px;
  background-color: black;
  opacity: 0.2;
}
.avatar-container:hover .delete-icon {
  display: block;
  cursor: pointer;
}
.member-list {
  width: 100% !important;
  margin-bottom: 65px !important;
  .ant-card-extra {
    width: 65%;
  }
}

// message
.ant-message {
  .ant-message-custom-content {
    display: flex;
    align-items: center;
  }

  .ant-message-custom-content .anticon {
    position: static;
  }
}

// select option in modal
:has(.modal-select-option) {
  .ant-select-dropdown {
    position: fixed;
  }
}
