@import './../../../../styles/variable';

.master-data-skill {
  .dropBox {
    width: 200px;
  }

  .select-position {
    height: 35px !important;
    border-width: 0 !important;
  }

  .profileContentInfoAlert {
    color: $color-navActive;
    line-height: 2rem;
    font-size: 1.1rem;
    padding: 40px;
    margin-left: 40%;
  }
}
