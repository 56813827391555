@import './../../../../styles/variable.scss';

ul {
  list-style: none;
  padding-left: 0;
}

.content {
  margin-bottom: 100px;
  .export {
    margin-left: 10px;
    width: 137px;
  }

  .paddingLeft {
    padding-left: 20px;
  }

  .errorNoData {
    text-align: center;
    color: $color-red;
    padding: 20px 0;
  }

  .flex {
    display: flex;
  }

  .center {
    text-align: center;
  }

  .button {
    display: flex;
    justify-content: flex-end;
    height: 40px;
    margin-top: -5px;
  }

  .profileBox {
    margin-top: 20px;
    cursor: pointer;
    .assetHeader {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;
      cursor: auto;
    }

    .assetHeaderRow {
      background-color: $color-headTable !important;
    }

    :global {
      .MuiTableCell-root {
        text-align: left;
      }
    }

    .assetContent {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;
      td {
        cursor: pointer;
      }
    }

    .assetHeader tr {
      background-color: $color-headTable !important;
    }

    tr:nth-child(odd) {
      background-color: $bg-white;
    }

    tr:nth-child(even) {
      background-color: $bg-tableStripped;
    }
  }

  .profileBox {
    padding: 0;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;

    :global {
      .MuiTableCell-root {
        white-space: normal !important;
        word-wrap: break-word !important;
      }
      .css-15wwp11-MuiTableHead-root {
        .MuiTableCell-root {
          color: $color-titleBody;
          font-weight: 500;
        }
      }
    }

    :global {
      .css-o9ey65 {
        position: relative;
        .MuiTablePagination-displayedRows.css-1chpzqh {
          position: absolute;
          left: 17px;
        }

        .MuiTablePagination-actions {
          .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
            color: $color-menuActive;
            font-size: 30px;
          }
        }
      }
    }

    table {
      width: 100%;
      margin: 0;
      table-layout: fixed;

      .profileContentInfo {
        word-wrap: break-word;
        font-weight: normal;
      }

      .thead {
        background-color: $color-headTable;

        .profileContentInfo {
          color: $color-titleBody;
        }
      }

      tr {
        font-weight: 500;
        color: $color-charcoal;
      }
    }
    .createButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 18px;
      height: 30px;
      background: $color-navActive;
      border-radius: 5px;
      color: $color-white;
      width: 90px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      .textButton {
        width: 70px !important;
      }

      .imgReset {
        margin: '0 10px 0 0';
      }

      &:hover {
        background: $color-navActive;
        color: $color-white;
      }

      &:active {
        background: $color-blue !important;
        color: $color-white !important;
      }
    }
  }

  .boxWaiting {
    margin: 19px 0 0 23px;
    height: 72px;
    background: $bg-errorImport;
    border: 1px solid $color-borderError;
    box-sizing: border-box;

    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;
    .boxWaitingContent {
      padding: 18px 0 15px 20px;
      display: flex;
      img {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
      .boxWaitingText {
        color: $color-error;
        .waitingText {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
        }
        .waitingNumber {
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
        }
      }
    }
  }

  .boxFilter {
    background: $bg-tooltip;
    margin: 19px 0 0 23px;
    height: auto;
    padding: 15px;

    border: 1px solid $color-wheat;
    box-sizing: border-box;

    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;

    .paddingElementFilter {
      padding-bottom: 20px;
    }

    .paddingElementTop {
      padding-top: 20px;
    }

    .marginElementBottom {
      margin-bottom: 30px;
    }

    .borderTop {
      border-top: 1px solid $color-borderTable;
    }

    .text {
      color: $color-titleBody;
      font-weight: 500px;
      font-size: 16px;
    }

    .division {
      cursor: pointer;
      position: relative;
      width: 100%;

      .total {
        position: absolute;
        right: 0px;
        width: auto;
        padding: 6px;
        background: $color-headTable;
        color: $color-titleBody;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
    }

    .reset {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 22px 32px;
      margin-top: 10px;
      height: 36px;
      background: $color-navActive;
      border-radius: 5px;
      color: $color-white;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      .imgReset {
        margin: '0 10px 0 0';
      }

      &:hover {
        background: $color-navActive;
        color: $color-white;
      }

      &:active {
        background: $color-blue !important;
        color: $color-white !important;
      }
    }

    li {
      padding-bottom: 25px;
    }
  }
  .linkRequest {
    color: $color-titleExport;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none;
  }
  .email {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 0 0;
    color: $color-email;
  }
  .colorWaiting {
    color: $color-blue1;
  }
  .colorDeny {
    color: $bg-menuNumberInfo;
  }
  .colorAccept {
    color: $color-active;
  }

  .infoDetail {
    display: flex;
    padding: 23px 20px 20px 20px;
    background-color: $color-headTable;
    .statusInfo {
      line-height: 20px;
    }
    .avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .info {
      width: 100%;
    }
    .linkRequest {
      line-height: 18px !important;
    }
    .email {
      line-height: 18px !important;
    }
    .buttonAcceptHr {
      margin-top: 5px;
      .hrAccept {
        width: 170px;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
  .inputSelectMulti {
    appearance: none;
    width: 100%;
    cursor: pointer;
    border: 1px solid $color-inputColor;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: $color-white;
    justify-content: center;
    align-items: flex-start;
    padding: 5px 0;
    padding-left: 13px;
    height: 40px;
    color: $color-truncate;
    margin-right: 0px;
    input {
      cursor: pointer;
    }
    :global{
      input.Mui-disabled {
        -webkit-text-fill-color: $color-charcoal !important;
      }
    }
  }

  .paddingSearch {
    padding-top: 20px;
  }

  .infoProfile {
    padding: 15px 25px;
    .infoProfileElement {
      padding: 20px 0;
      margin-right: 20px;
      border-bottom: 1px solid $color-borderTable;
      .infoProfileLabel {
        color: $color-titleBody;
        font-weight: 500;
      }
      .infoProfileContent {
        color: $color-charcoal;
        font-weight: normal;
      }
    }
  }

  .modal {
    :global {
      .modal-body {
        padding: 0;
      }
    }
    @media (min-width: 1024px) {
      :global {
        .MuiPaper-root {
          min-width: 673px;
          margin: 1.75rem auto;
        }
      }
    }
  }

  .modal {
    :global {
      .modal-body {
        padding: 0;
      }
    }
    @media (min-width: 1024px) {
      :global {
        .MuiPaper-root {
          min-width: 673px;
          margin: 1.75rem auto;
        }
      }
    }
  }

  .modalCreateContract {
    :global {
      .MuiGrid-item {
        margin-top: 4px;
      }
    }

    .exampleSelectMulti {
      appearance: none;
      width: 100%;
      cursor: pointer;
      border: 1px solid $color-inputColor;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: $color-white;
      justify-content: center;
      align-items: flex-start;
      padding: 5px 0;
      padding-left: 13px;
      height: auto;
      color: $color-truncate;
    }
    .exampleSelectMulti:hover {
      cursor: pointer;
    }

    .footerModal {
      width: 100%;
      padding-left: 15px;
      margin-bottom: -15px;
      :global {
        .MuiGrid-item {
          margin-top: 0px;
          padding-top: 15px;
        }
      }
    }
    .colorCheckBox {
      :global {
        .MuiTypography-root {
          font-size: 14px;
          color: $color-charcoal;
        }
      }
    }
  }
}

.numberFuture {
  background: #c9eaff;
  border-radius: 5px;
  width: 18px;
  height: 15px;

  font-size: 14px;
  color: #2D99FF;
  line-height: 22px;
  padding: 0px 5px;
  margin-left: 10px;
}
