@import "./../../../../styles/variable";

.holiday-master-data {
  .dropBox {
    width: 171px;
  }
  .profileContentInfoAlert {
    color: $color-navActive;
    line-height: 2rem;
    font-size: 1.1rem;
    padding: 40px;
    margin-left: 40%;
  }
  .calendar {
    margin-right: 10px;
  }
  .datailCalendar {
    width: 280px;
  }
  .dateTime {
    :global {
      .input-group-text {
        border-top-right-radius: 0.375rem !important;
        border-bottom-right-radius: 0.375rem !important;
      }
    }
    img {
      width: 20px;
    }
  }
  .input-search-box {
    margin-top: 1.3px !important;
  }
}
@media (min-height: 620px) and (max-height: 1300px) {
  .modal-custom-holiday {
    margin-top: -180px;
  }
}
.modal-custom-holiday {
  .form-input {
    border: 1px solid #eee;
    border-radius: 4px;
    width: 100%;
    height: 50px;
    padding: 0 12px;
    transition: 0.25s ease;
  }

  .form-input:focus,
  .form-input:hover {
    border-color: $color-green;
  }

  .field-error {
    border: 1px solid red;
  }

  .field-error:focus,
  .field-error:hover {
    border-color: red;
  }

  .form-field {
    position: relative;
    &:hover {
      label {
        color: $color-green;
      }
    }
  }

  .formField {
    position: relative;
    :global {
      .react-datepicker-popper {
        z-index: 10;
      }
      .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        left: -15px;
      }
      .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
        left: -15px;
      }
    }
  }

  .form-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
    user-select: none;
    color: #999;
    pointer-events: none;
    transition: 0.25s ease;
  }
  .form-input:focus,
  .focus-visible:focus:not(:focus-visible) {
    outline: 0;
  }

  .form-input:focus + .form-label {
    top: 0;
    left: 11px;
    padding: 0 10px;
    display: inline-block;
    background-color: $color-white;
    color: $color-green;
  }

  .label-error {
    color: red !important;
  }

  .input-focus-custom {
    top: 0;
    left: 11px;
    padding: 0 10px;
    display: inline-block;
    background-color: $color-white;
    color: $color-green;
  }
  .error-birthday {
    margin-left: 10px;
    margin-top: 5px;
  }
  .MuiPaper-root {
    min-width: 600px !important;
  }
  .title-hodiday {
    margin-bottom: 20px;
  }
}
