@import './../../styles/variable.scss';
.modalCommon {
  .modalTitle {
    background: $color-titleModal;
    border-radius: 5px 5px 0px 0px;
    width: auto;
    height: 50px;
    h5 {
      color: $bg-body;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 21px;
      margin: -5px 0 0 0;
    }
    span {
      height: 30px;
      width: 30px;
      color: #fea628;
      border: 1px solid #223354;
      box-sizing: border-box;
      text-align: center;
      padding-top: 5px;
      margin-top: -12px;
      padding-top: 2px;
      border-radius: 50%;
      display: inline-block;
    }
  }
  .modalBody {
  }
  .modalFooter {
    justify-content: center;
    .buttonSubmit {
      width: -webkit-fill-available;
      background: $color-buttonSubmit;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      height: 36px;
      color: $bg-body;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      border: 1px solid $bg-yellow1;
      &:active {
        background-color: $color-buttonSubmitActive !important;
        color: $bg-body !important;
      }
      &:hover {
        color: $bg-body;
        background-color: $bg-white;
        color: $bg-yellow1;
        border: 1px solid $bg-yellow1;
      }
    }
  }
}
