@import 'styles/variable.scss';

@mixin statistical {
  .statistical {
    .statistical-item {
      border-radius: 5px;
      padding: 13px 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $bg-plum;
      margin-bottom: 5px;

      .statistical-item-title {
        line-height: 0;
        margin-left: 8px;
        color: $color-navy;
      }
    }

    .hourglass-padding {
      img {
        margin-left: 4px;
      }
      .statistical-item-title {
        margin-left: 10px;
      }
    }

    .deleted-hourglass-padding {
      .statistical-item-title {
        margin-left: 5px;
      }
    }

    .special-item {
      background-color: $color-totalEmployee;
    }
  }
}

.request-timekeeping {
  @include statistical;

  .menu-slider-around {
    width: 100%;
  }
  .button-group {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    height: 40px;
    button {
      height: 100%;
    }
  }
  .request {
    position: absolute;
    right: 0;
    top: 10px;
  }
  .statistical {
    padding: 15px 0 10px 0;
    border-top: 1px solid $color-wheat;
  }

  .filter-by-date {
    margin-bottom: 26px;
  }

  .filter-by-input {
    padding-top: 15px;

    .padding-element-filter {
      padding-bottom: 15px !important;
    }
  }
  .padding-element-filter {
    @media (min-width: 1024px) and (max-width: 1500px) {
      flex-direction: column !important;
      .date-filter {
        width: -webkit-fill-available;
      }
      .date-filter:first-child {
        margin-bottom: 18px;
      }
      .mr-1 {
        display: none;
      }
    }
  }

  .added-info {
    color: $color-silver;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 130px;
    display: inline-block;
    .straight {
      display: flex;
    }
    p {
      margin: 0;
    }
    @media screen and (max-width: 1490px) {
      flex-direction: column;
    }
  }

  .number-request-day {
    margin-right: 7.65px;
    position: relative;

    .number {
      padding: 0px 4px;
      border: 1px solid transparent;
      border-radius: 50%;
      font-size: 10px;
      background: $color-red;
      color: $color-table;
      position: absolute;
      left: -7px;
      top: -7px;
    }
  }

  .exampleSelectMulti input {
    padding: 2px 0 5px;
    font-size: 16px;
    cursor: pointer;
  }

  table {
    th {
      &:first-child {
        width: 76px;
      }
    }

    td {
      cursor: pointer;
      word-break: break-all;
      .seperate {
        margin: 0 5px 0 2px;
      }
      .link-work-fl {
        color: $color-black;
        text-decoration: none;
      }
    }

    .dot-status-accept {
      width: 13px;
      height: 13px;
      border-radius: 13px;
      margin-top: 15px;
    }

    .color-blue {
      background-color: $color-blue1;
    }

    .color-green {
      background-color: $color-active;
    }
  }

  .bolder-500 {
    font-weight: 500;
  }
}

.border-bottom-tooltip-none {
  .MuiTooltip-tooltip {
    border: none !important;
  }
}
