@import 'styles/variable.scss';

.formInfo {
    align-items: center;
    background-color: $bg-salmon;
    padding: 20px;
    justify-content: space-between;
    margin-bottom: 12px;
    
    .name {
        color: $color-navy;
        font-size: 18px;
        font-weight: 500;
        margin: 0;
    }

    .email {
        font-weight: 400;
        color: #909090;
    }

    :global {
        .btn-black {
            font-weight: 500;
            font-size: 14px;
        }
    }
}