@import "styles/variable.scss";

.rental-price-list {
  margin-bottom: 100px;
  .wrapper-top {
    background: $bg-boder-top;
    width: 100%;
    overflow-x: scroll;
    cursor: pointer;
    .top {
      margin-top: -22px;
    }
  }
  .wrapper-top {
    margin-top: -10px;

    p {
      font-size: 16px;
    }
  }
  .wrapper-top::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
  .wrapper-top.thin::-webkit-scrollbar {
    width: 2px;
  }
  .wrapper-top::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-white;
  }
  .wrapper-top::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-navActive;
  }
  .button {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    height: 40px;
    margin-top: -5px;
  }
  .export {
    margin-left: 10px;
    width: 137px;
  }
  .create {
    margin-left: 10px;
    width: 178px;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }

  .export {
    margin-left: 10px;
    width: 150px;
  }

  .padding-left {
    padding-left: 10px;
  }

  .error-no-data {
    text-align: center;
    color: $color-red;
    padding: 20px 0;
  }

  .flex {
    display: flex;
  }

  .center {
    text-align: center;
  }

  .button {
    display: flex;
    justify-content: flex-end;
    height: 40px;
    margin-top: -5px;
  }

  .profile-box {
    margin-top: 20px;
    cursor: pointer;
    .long {
      width: 145px;
    }
    .price {
      width: 15%;
    }
    .center {
      text-align: center;
    }
    .nor {
      width: 100px;
      text-align: center;
    }
    .short {
      width: 60px;
      text-align: center;
    }
    .total {
      color: #223354;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
    .number {
      text-align: right;
    }
    .asset-header {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;
      cursor: auto;
    }
    .head-title {
      th {
        background-color: $bg-salmon !important;
        border-bottom: none;
      }
    }

    .asset-header-row {
      background-color: $color-headTable !important;
    }

    :global {
      .MuiTableCell-root {
        text-align: left;
      }
    }

    .asset-content {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;
      td {
        cursor: pointer;
      }
    }

    .asset-header tr {
      background-color: $color-headTable !important;
    }
    tr:nth-child(odd) {
      background-color: $bg-white !important;
    }
    tr:nth-child(even) {
      background-color: $bg-tableStripped;
    }
  }

  .profile-box {
    padding: 0;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;

    :global {
      .MuiTableCell-root {
        white-space: normal !important;
        word-wrap: break-word !important;
      }
      .css-15wwp11-MuiTableHead-root {
        .MuiTableCell-root {
          color: $color-titleBody;
          font-weight: 500;
        }
      }
    }

    :global {
      .css-o9ey65 {
        position: relative;
        .MuiTablePagination-displayedRows.css-1chpzqh {
          position: absolute;
          left: 17px;
        }

        .MuiTablePagination-actions {
          .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
            color: $color-menuActive;
            font-size: 30px;
          }
        }
      }
    }
    table {
      margin: 0;
      table-layout: fixed;
      .profile-content-info {
        word-wrap: break-word;
        font-weight: normal;
      }
      .thead {
        background-color: $color-headTable;

        .profile-content-info {
          color: $color-titleBody;
        }
      }

      tr {
        font-weight: 500;
        color: $color-charcoal;
      }
    }
    @media only screen and (max-width: 1500px) {
      .table-scroll {
        overflow-x: scroll;
        cursor: pointer;
        width: 160%;
      }
    }
    .scroll-custom::-webkit-scrollbar {
      width: 2px;
      height: 5px;
    }
    .scroll-custom::-webkit-scrollbar {
      width: 2px;
    }
    .scroll-custom::-webkit-scrollbar-track {
      border-radius: 10px;
      background: $color-white;
    }
    .scroll-custom::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $color-navActive;
    }
    .create-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 17px 0;
      height: 30px;
      background: $color-navActive;
      border-radius: 5px;
      color: $color-white;
      width: 95px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      .text-button {
        width: 70px !important;
      }

      .img-reset {
        margin: "0 10px 0 0";
      }
      &:hover {
        background: $color-navActive;
        color: $color-white;
      }
      &:active {
        background: $color-blue !important;
        color: $color-white !important;
      }
    }
  }
  .table-list {
    border: unset;
  }
  .box-waiting {
    margin: 19px 0 0 23px;
    box-sizing: border-box;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;
    .box-waiting-content.second {
      padding-top: 0;
    }
    .box-waiting-content {
      padding: 18px 0 15px 20px;
      display: flex;
      .content {
        padding-top: 18px;
        display: flex;
        width: 95%;
        border-top: 1px solid #e7e7e7;
      }
      img {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
      img.total-assets {
        width: 29px !important;
        height: 29px !important;
      }
      .box-waiting-text {
        .waiting-text {
          color: $bg-nav;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
        }
        .waiting-number {
          color: $color-menuActive;
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
        }
      }
    }
  }
  .box-filter {
    background: $bg-tooltip;
    margin: 19px 0 0 23px;
    height: auto;
    padding: 15px;
    border: 1px solid $color-wheat;
    box-sizing: border-box;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;
    .selected {
      background: $color-totalEmployee;
    }
    .not-selected {
      background: $bg-thTable1;
    }
    .select-field {
      border-radius: 5px;
      padding: 15px;
      display: flex;
      position: relative;
      cursor: pointer;
      margin-bottom: 5px;
      .img-filter {
        width: 20px;
        height: 20px;
      }
      .select-field-title {
        margin-left: 15px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $color-navActive;
        word-wrap: break-word;
      }
      .select-field-total {
        position: absolute;
        right: 17px;
        color: $color-navActive;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .padding-element-filter {
      cursor: pointer;
      padding-bottom: 20px;
      .exampleSelectMulti {
        appearance: none;
        width: 100%;
        cursor: pointer;
        border: 1px solid $color-inputColor;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: $color-white;
        justify-content: center;
        align-items: flex-start;
        padding: 5px 0;
        padding-left: 13px;
        height: 40px;
        color: $color-truncate;
      }
      .exampleSelectMulti:hover {
        cursor: pointer;
      }
    }
    .padding-element-top {
      padding-top: 20px;
    }
    .margin-element-bottom {
      margin-bottom: 30px;
    }
    .border-top {
      border-top: 1px solid $color-borderTable;
    }
    .text {
      color: $color-titleBody;
      font-weight: 500px;
      font-size: 16px;
    }
    .month {
      display: flex;
    }
    @media only screen and (max-width: 1480px) {
      .month {
        display: block !important;
      }
      .between {
        display: none;
      }
      .from-date {
        margin-bottom: 20px;
      }
    }
    .error-input {
      display: none;
    }
    .between {
      margin: 5px 5px 0 5px;
    }
    .division {
      cursor: pointer;
      position: relative;
      width: 100%;
      display: flex;
      .dot-status-accept {
        width: 13px;
        height: 13px;
        border-radius: 13px;
        margin-top: 15px;
      }
      .color-blue {
        background-color: $color-blue1;
      }
      .color-green {
        background-color: $color-active;
      }
      .total {
        position: absolute;
        right: 0px;
        width: auto;
        padding: 6px;
        background: $color-headTable;
        color: $color-titleBody;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .reset {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 22px 32px;
      margin-top: 10px;
      height: 36px;
      background: $color-navActive;
      border-radius: 5px;
      color: $color-white;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      .img-reset {
        margin: "0 10px 0 0";
      }

      &:hover {
        background: $color-navActive;
        color: $color-white;
      }

      &:active {
        background: $color-blue !important;
        color: $color-white !important;
      }
    }

    li {
      padding-bottom: 25px;
    }
  }
  .link-request {
    color: $color-titleExport;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none;
  }
  .email {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 0 0;
    color: $color-email;
    word-break: break-word;
  }
  .user-name {
    font-weight: 500;
  }
  .color-waiting {
    color: $color-blue1;
  }
  .color-deny {
    color: $bg-menuNumberInfo;
  }
  .color-accept {
    color: $color-active;
  }

  .info-detail {
    display: flex;
    padding: 23px 20px 20px 20px;
    background-color: $color-headTable;
    .status-info {
      line-height: 20px;
    }
    .avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .info {
      width: 100%;
    }
    .link-request {
      line-height: 18px !important;
    }
    .email {
      line-height: 18px !important;
    }
  }
  .info-profile {
    padding: 15px 25px;
    .info-profile-element {
      padding: 20px 0;
      margin-right: 20px;
      border-bottom: 1px solid $color-borderTable;
      .info-profile-label {
        color: $color-titleBody;
        font-weight: 500;
      }
      .info-profile-content {
        color: $color-charcoal;
        font-weight: normal;
      }
    }
  }
  .scroll-custom {
    max-height: 700px !important;
    .sum-header {
      position: sticky;
      top: 40px;
      th {
        background-color: $bg-salmon !important;
        z-index: 1000;
      }
    }
    @media (min-width:1500px) and (max-width: 2000px) {
      .custom-header {
        top: 64px;
      }
    }
    @media screen and (max-width: 1282px) {
      .custom-header {
        top: 63px;
      }
    }
  }

  .sum {
    background-color: $bg-salmon !important;
    font-weight: bold !important;
  }
}
