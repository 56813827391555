.select-field-information {
  min-width: 200px;
}
.title-project {
  color: #223354 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.title {
  font-weight: 600;
}
