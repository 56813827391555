@import 'styles/variable.scss';

.exampleSelectMulti {
  input {
    padding: 2px 0 5px;
  }
}

input:disabled{
  cursor: pointer;
}

.text-input-dark {
  input.Mui-disabled {
    -webkit-text-fill-color: $color-charcoal !important;
  }
}

.activity-assets {
  .activity-content-wrapper {
    margin-top: 19px;
    padding: 20px 14px;
    box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
    height: 750px;
    overflow-y: auto;
    background-color: $color-table;

    .activity-item {
      margin-bottom: 20px;
      .activity-time {
        color: $color-navy;
        border-bottom: 1px solid $color-wheat;
        padding-bottom: 5px;
        font-weight: bold;
        margin-bottom: 0;
      }

      .activity-time-detail {
        display: flex;
        border-bottom: 1px solid $color-wheat;
        img {
          width: 20px;
          height: 20px;
          position: absolute;
          right: -10px;
          top: 25%;
          background-color: $color-table;
        }

        .activity-left-content {
          position: relative;
          border-right: 1px solid $color-navy;
          color: $color-navy;
          text-align: center;
          padding: 8px 30px 15px 8px;

          .activity-left-content-dd-mm {
            display: block;
          }

          .activity-left-content-hh {
            font-size: 18px;
            font-weight: bold;
          }
        }

        .activity-right-content {
          padding: 8px 15px 0 30px;

          .activity-assets-name {
            margin-bottom: 1px;
          }

          .activity-from-to,
          .activity-made-by {
            margin-bottom: 0;
            color: $color-silver;
          }
        }
      }
    }
  }
  .link-to-asset {
    font-weight: 500;
  }
  .padding-element-filter.align-items-center {
    @media (min-width: 1024px) and (max-width: 1500px) {
      flex-direction: column !important;
      div:first-child{
        margin-bottom: 6px;
      }
      .mr-1 {
        display: none;
      }
    }
  }
}
