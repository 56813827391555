@import "styles/variable";

.progress {
  &__info {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .fileName {
      font-weight: 500;
    }

    .indicator {
      color: #8D8D8D;
    }
  }
}

.error {
  background: $bg-errorImport;
  border-radius: 5px;
  padding: 10px 35px 10px 15px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.fileInvalid {
  height: 15px;
  margin-right: 5px;
}

.iconImport {
  width: 25px;
  margin-bottom: 15px;
}

.link {
  color: #0085FF;
  text-decoration: underline;
}
