@import "./../../../../styles/variable.scss";
.layout-request {
    p {
        margin: 0 0 0 0;
    }
    .no-data {
        margin-left: 50%;
    }
    .request {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        float: right;
        color: $color-white;
        background: $color-yellow;
        border-radius: 5px;
        padding: 22px 32px;
        width: 167px;
        height: 36px;
        border: none;
        margin-right: 5px;
        span.text-export {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            margin-left: 10px;
        }
    }
    .request-top-right {
        text-align: right;
    }
    .img-friend {
        width: 45px;
        margin-right: 12px;
    }
    .breadcrumb {
        background-color: $bg-body !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        a {
            color: $color-truncate;
            font-weight: 500;
        }
        li.active.breadcrumb-item {
            color: $color-navy !important;
            font-weight: 600;
        }
    }
    .request-box {
        border: 1px solid $color-inputColor;
        box-sizing: border-box;
        box-shadow: 0px 5px 25px $color-boderRequset;
        border-radius: 5px;
        background: $bg-white;
        padding: 0;
        padding-bottom: 60px;
        color: $color-navActive;
        margin-top: 20px;
        .request-object {
            width: -webkit-fill-available;
            overflow: scroll;
            height: 300px;
            overflow-x: hidden;
        }
        .request-object::-webkit-scrollbar {
            width: 8px;
        }

        .request-object.thin::-webkit-scrollbar {
            width: 2px;
        }

        .request-object::-webkit-scrollbar-track {
            border-radius: 10px;
            background: $color-white;
        }

        .request-object::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: $bg-yellow1;
        }
        .part-title {
            padding: 0;
            border-bottom: 1px solid $color-grey;
            p.title {
                color: $color-navy;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 32px;
                padding: 28px;
            }
        }
        .type-request {
            padding-left: 150px;
            padding-right: 60px;
            padding-top: 30px;
            .item-request {
                min-height: 60px;
                padding: 10px;
                border-bottom: 1px solid $color-grey;
                p {
                    font-style: normal;
                    font-size: 14px;
                }
                p.item {
                    line-height: 24px;
                    font-weight: 600;
                }
                p.detail {
                    color: $color-charcoal;
                    line-height: 20px;
                    font-weight: 400;
                }
            }
        }
        .item-persionnel {
            padding: 10px 10px 10px 10px;
            img.avatar {
                width: 80px;
                border-radius: 50%;
                height: 80px;
                object-fit: cover;
            }

            h4.media-heading {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: $color-navy;
            }
            p.infor-personnel {
                color: $color-truncate;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                margin: 0 0 0 0;
            }
            .media {
                padding: 20px;
                box-sizing: border-box;
                border-radius: 5px;
                overflow: auto;
                .media-body {
                    margin-left: 10px;
                }
            }
        }
    }
}
