@import "./../../../../styles/variable";

.text-employee {
  display: flex;
  align-items: flex-end;

  .text-truncate {
    line-height: 29px;
  }

  .number-employee {
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    color: $color-yellow;
    margin-left: 10px;
  }
}

p {
  margin-bottom: 5px;
}

.table {
  tr {
    td {
      border-top: none;
      a {
        text-decoration: none;
      }
    }
  }

  .border-right {
    border-right: 1px solid $color-grey !important;
  }
}

.box-list {
  .employees {
    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      td:last-child {
        background-color: #f3f8ff;
      }
      &:nth-child(even) {
        background-color: $bg-rowTable;
        td:last-child {
          background-color: #e9ebee;
        }
      }
    }
    tbody {
      display: block;
      height: 170px;
      overflow: auto;
      @media (min-width: 1024px) and (max-width: 1256px) {
        height: 194px;
      }
    }
    .border-right {
      border-right: 1px solid #ffdba9 !important;
    }
  }
  .table {
    color: $color-charcoal;
    thead {
      th,
      p {
        color: #223354;
        background-color: inherit;
        border: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      }
      th {
        position: sticky;
        top: 0px;
        background-color: $bg-thTable;
      }

      .title-inth {
        display: flex;
        height: 80px;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
      }

      P:last-child {
        margin-bottom: 0;
        color: $color-blueGray;
        font-size: 20px;
      }

      tr {
        position: sticky;
        top: 0px;
        background-color: $bg-body;
      }
    }

    tbody {
      overflow-y: auto;
      width: 100%;
      tr {
        &:nth-child(even) {
          background-color: $bg-rowTable;
        }

        td {
          padding: 0.8rem 1rem;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #333333;
        }
      }
    }
  }
}

.scroll {
  ::-webkit-scrollbar {
    width: 2px;
  }

  // /* Track */
  // ::-webkit-scrollbar-track {
  //   border-radius: 40px;
  // }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }

  // /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: $color-navy;
  // }
}

.form-group {
  margin-bottom: 0;
}

.info-top-body {
  display: flex;
  align-items: baseline;

  .text-truncate {
    line-height: 29px;
  }

  .number-employee {
    font-weight: bold;
    font-size: 34px;
    line-height: 40px;
    color: $color-yellow;
    margin-left: 10px;
    margin-bottom: 5px;
  }
}

.statistical {
  .ct-scroll-table {
    height: 275px;
    width: 100%;
    overflow-y: auto;
  }
}

.info-employee {
  .link-to-employees {
    &:hover {
      text-decoration: none;
    }
  }
  .box {
    text-align: center;
    background-color: $bg-plum;
    border-radius: 5px;
    padding: 10px 0 2px 0;
    margin-bottom: 15px;

    p {
      margin-bottom: 5px;
    }

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

    .info {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
    }

    &.mra-5 {
      margin-right: -5px;
    }
    &.mla-5 {
      margin-left: -5px;
    }
    &:hover {
      background-color: #d6d9dd;
    }
  }
  .block {
    height: 110px !important;
    padding: 35px;
  }
}

.info-gender {
  font-weight: 500;
  font-size: 34px;
  text-transform: uppercase;

  .img-man {
    background-size: contain;
  }

  .img-woman {
    background-size: contain;
  }
  span {
    font-weight: 400;
  }
}
.statistical-gender {
  .table {
    margin-bottom: 20px;
    td {
      padding: 0;
    }
  }

  .loading-number-employee {
    height: 140px;
  }
}
// ====================== birthday list ============================

.employee-birthday-list {
  .ct-scroll-table {
    height: 274px;
    width: 100%;
    overflow-y: auto;
    table {
      tr {
        height: 60px;
      }
    }
  }
}

.avatar {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  border-radius: 50%;
  img {
    height: 40px;
    width: 40px;
  }
}

.employee-list {
  p {
    margin-bottom: 5x;
  }

  .employee-info {
    display: flex;

    .name {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $color-navy;
      margin-bottom: 0;
    }

    .division {
      font-size: 14px;
      line-height: 22px;
      color: $color-silver;
    }
  }

  .text-date {
    font-size: 14px;
    line-height: 22px;
    color: #526487 !important;
  }
}

// ================ session 2 =========================
.btn-dropdown {
  width: 170px;
}

.employees-map {
  .box-session {
    padding: 20px 15px 50px 15px;
    label {
      margin: 0 0 0 0;
    }
    label.month {
      margin-right: 6px;
      width: -webkit-fill-available;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      color: $color-navy;
      font-weight: 500;
      margin-top: -16px;
    }
    label.employees {
      font-size: 12px;
      line-height: 14px;
      color: $color-navy;
      font-weight: 500;
      margin-left: 20px;
    }
    .date-time {
      label {
        top: -5px;
      }
      .MuiOutlinedInput-root {
        height: 40px;
      }
    }
  }

  .map-search {
    display: flex;
    .btn-dropdown,
    .date-time {
      float: left;
    }
  }
  .chart-js-line {
    height: 350px;
  }
  .filter-employee-division {
    width: 190px;
    margin-left: auto;
    margin-right: 0;
  }
}

.celebrate-list {
  .ct-scroll-table {
    height: 383px;
    width: 100%;
    overflow-y: auto;
    @media (min-width: 1024px) and (max-width: 1256px) {
      height: 406px;
    }
  }
}

// ================ session 3 =========================
.division-map {
  .box-session {
    padding: 20px;
  }
}

.table-total-employee {
  padding-bottom: 30px;
  .table {
    border-collapse: separate;
    border-spacing: 0;
    border: none;
    border-right: 1px solid $color-borderTable;
    tr {
      .first {
        border-left: 1px solid $color-borderTable;
      }

      th,
      td {
        padding: 1rem 0.5rem !important;
      }
    }

    thead {
      th {
        border-top: 1px solid $color-borderTable;
        background-color: $bg-thTable1;
        color: #223354;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }
    }

    td {
      font-size: 14px;
      color: $color-charcoal;
    }
  }

  .ct-scroll-table {
    border-bottom: 1px solid $color-borderTable;
    height: 430px;
    width: 100%;
    overflow-y: auto;
  }

  .table-responsive {
    display: contents;
  }
}

.box-map {
  .box-note {
    .item-note {
      width: 50%;
      float: left;
      font-size: 14px;
      line-height: 22px;
      color: $color-charcoal;

      .bg-radio {
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 42px;
        margin-right: 10px;
      }
    }
  }

  .map {
    width: 160px;
    height: 160px;
    margin-right: 40px;
    margin-left: 40px;
  }
}

.division-map {
  .info-top-body {
    margin-bottom: 20px;
  }

  .level-map {
    display: flex;
  }
}

.dashboard-employees {
  .total-employees {
    height: 320px !important;
  }
  .turnover {
    background: #fea628 !important;
    .text-center {
      p {
        color: $color-white !important;
      }
    }
  }
  .color-residual {
    color: #223354 !important;
  }

  .title-division {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #223354 !important;
  }
  .MuiOutlinedInput-root {
    height: 40px !important;
  }
}
.birthdayActive{
  background: #C9EAFF !important;
}