@import './../../styles/variable.scss';

.modalHeader {
  background: $bg-modal;
  border-radius: 5px 5px 0px 0px;
  width: auto;
  height: 50px;
  color: $color-white;

  :global {
    .modal-title {
      color: $color-white;
      font-weight: 500;
      font-size: 20px;
      line-height: 21px;
    }

    .close {
      padding: 0.8rem !important;
      padding-top: 8px !important;
      span {
        height: 30px;
        width: 30px;
        color: $color-yellow;
        border: 1px solid $color-navy;
        box-sizing: border-box;
        text-align: center;
        padding-top: 2px;
        border-radius: 50%;
        display: inline-block;
      }
    }
  }
}

:global {
  .modal-header {
    padding: 1rem;
  }
}

.ctDialog {
  :global {
    .modal-body {
      padding: 1.5rem;
    }

    .modal-body {
      max-height: 800px;
      overflow: scroll;
      background-color: white;
      form {
        margin-top: 0 !important;
        label.form_label_date {
          position: absolute;
          top: -20%;
          left: 4%;
          z-index: 1;
          background: $color-white;
          font-size: 0.8rem;
        }
      }
    }
    .modal-body::-webkit-scrollbar {
      width: 2px;
    }
    .modal-body.thin::-webkit-scrollbar {
      width: 2px;
    }
    .modal-body::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $bg-yellow1;
    }
  }

  @media (min-width: 576px) {
    :global {
      .MuiPaper-root {
        min-width: 500px;
        margin: 1.75rem auto;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        background-color: $bg-modal;
        max-width: 700px;
      }
    }
  }
}

.headModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-white;
  font-weight: 500;
  font-size: 20px;
  padding: 10px 20px;

  .iconClose {
    width: 30px;
    height: 30px;
    border: 1px solid #223354;
    box-sizing: border-box;
    border-radius: 50%;
    font-size: 19px;
    padding-left: 5px;
    color: #fea628;

    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
}
