@import "styles/variable.scss";

.importUser {
  .MuiPaper-root {
    max-width: 500px !important;
    :global {
      .modal-body {
        max-height: 400px;
        overflow: scroll;
      }
      .modal-body::-webkit-scrollbar {
        width: 2px;
      }
      .modal-body.thin::-webkit-scrollbar {
        width: 2px;
      }
      .modal-body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $bg-yellow1;
      }
    }
  }
  .progressImport {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .infor {
    position: absolute;
    z-index: 1;
    color: $color-grey1;
    font-size: 14px;
  }
  .iconImport {
    height: 20px;
    width: 25px;
    left: 48%;
    top: 13px;
  }
  .notifi {
    left: 38%;
    top: 40px;
  }
  .format {
    left: 39%;
    top: 60px;
  }
  .downloadTemplate {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: $color-managerNameBox;
    margin-bottom: 20px;
    cursor: pointer;
  }
  margin-top: 0;
  .labelImport {
    width: -webkit-fill-available;
    position: relative;
    top: 0;
    left: 0;
    .imgImport {
      width: -webkit-fill-available;
      height: 100px;
      cursor: pointer;
    }
  }
  .progress {
    position: absolute;
    right: 6%;
  }
  .error {
    background: $bg-errorImport;
    border-radius: 5px;
    padding: 10px 35px 10px 15px;
    margin-top: 10px;
  }
  .fileInvalid {
    height: 15px;
    margin-right: 5px;
  }
  .inforImport {
    color: #333333;
    font-weight: 500;
  }
  .footerModal {
    margin-top: 40px;
  }
}
