@import "./../../../../styles/variable.scss";

ul {
  list-style: none;
  padding-left: 0;
}

.export {
  margin-left: 10px;
  width: 137px;
}

.paddingLeft {
  padding-left: 20px;
}

.errorNoData {
  text-align: center;
  color: $color-red;
  padding: 20px 0;
  font-size: 1rem;
  font-weight: 100;
}

.flex {
  display: flex;
}

.center {
  text-align: center;
}

.button {
  display: flex;
  justify-content: flex-end;
  height: 40px;
  margin-top: -5px;
}

.textLeft {
  text-align: left;
}
.withStatusLabel {
  width: 120px;
}
.profileBox {
  margin-top: 20px;
  cursor: pointer;
  .assetHeader {
    color: $color-black;
    line-height: 2rem;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: auto;
  }

  .assetHeaderRow {
    background-color: $color-headTable !important;
  }

  :global {
    .MuiTableCell-root {
      text-align: left;
    }
  }

  .assetContent {
    color: $color-black;
    line-height: 2rem;
    font-size: 0.9rem;
    font-weight: bold;
    td {
      cursor: pointer;
    }
  }

  .assetHeader tr {
    background-color: $color-headTable !important;
  }

  tr:nth-child(odd) {
    background-color: $bg-white;
  }

  tr:nth-child(even) {
    background-color: $bg-tableStripped;
  }
}

.profileBox {
  padding: 0;
  box-shadow: 0px 5px 25px $color-black2;
  border-radius: 5px;

  :global {
    .MuiTableCell-root {
      white-space: normal !important;
      word-wrap: break-word !important;
    }
    .css-15wwp11-MuiTableHead-root {
      .MuiTableCell-root {
        color: $color-titleBody;
        font-weight: 500;
      }
    }
  }

  :global {
    .css-o9ey65 {
      position: relative;
      .MuiTablePagination-displayedRows.css-1chpzqh {
        position: absolute;
        left: 17px;
      }

      .MuiTablePagination-actions {
        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
          color: $color-menuActive;
          font-size: 30px;
        }
      }
    }
  }

  table {
    width: 100%;
    margin: 0;
    table-layout: fixed;

    .profileContentInfo {
      word-wrap: break-word;
      font-weight: normal;
    }

    .thead {
      background-color: $color-headTable;

      .profileContentInfo {
        color: $color-titleBody;
      }
    }

    tr {
      font-weight: 500;
      color: $color-charcoal;
    }
  }
}

.boxFilter {
  background: $bg-tooltip;
  margin: 19px 0 0 23px;
  height: auto;
  padding: 15px;

  border: 1px solid $color-wheat;
  box-sizing: border-box;

  box-shadow: 0px 5px 25px $color-black2;
  border-radius: 5px;

  .paddingElementFilter {
    padding-bottom: 20px;
  }

  .paddingElementTop {
    padding-top: 20px;
  }

  .marginElementBottom {
    margin-bottom: 30px;
  }

  .borderTop {
    border-top: 1px solid $color-borderTable;
  }

  .text {
    color: $color-titleBody;
    font-weight: 500px;
    font-size: 16px;
  }

  .division {
    cursor: pointer;
    position: relative;
    width: 100%;

    .total {
      position: absolute;
      right: 0px;
      width: auto;
      padding: 5px 8px;
      background: $color-headTable;
      color: $color-titleBody;
      border-radius: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .dotStatusAccept {
    width: 13px !important;
    height: 13px;
    border-radius: 13px;
    margin-top: 15px;
  }
  
  li {
    padding-bottom: 25px;
  }

  .reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    margin-top: 10px;
    height: 36px;
    background: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .imgReset {
      margin: "0 10px 0 0";
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }
}
.linkRequest {
  color: #333333;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
}
.email {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 0 0;
  color: $color-email;
}
.colorWaiting {
  color: $color-blue1;
}
.colorDeny {
  color: $bg-menuNumberInfo;
}
.colorAccept {
  color: $color-active;
}

.infoDetail {
  display: flex;
  padding: 23px 20px 20px 20px;
  background-color: $color-headTable;
  .statusInfo {
    line-height: 20px;
  }
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .info {
    width: 100%;
  }
  .linkRequest {
    line-height: 18px !important;
  }
  .email {
    line-height: 18px !important;
  }
  .buttonAcceptHr {
    margin-top: 5px;
    display: flex;
    .hrAccept {
      width: 140px;
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 10px;
      height: 40px;
    }
    .hrCancel {
      width: 140px;
      padding-left: 0px;
      padding-right: 0px;
      height: 40px;
      color: $color-menuActive;
      background-color: $bg-yellow2;
    }
  }
}
.infoProfile {
  padding: 15px 25px;
  .infoProfileElement {
    padding: 20px 0;
    margin-right: 20px;
    border-bottom: 1px solid $color-borderTable;
    .infoProfileLabel {
      color: $color-titleBody;
      font-weight: 500;
    }
    .infoProfileContent {
      color: $color-charcoal;
      font-weight: normal;
    }
  }
}

.modal {
  :global {
    .modal-body {
      padding: 0;
    }
  }
  @media (min-width: 1024px) {
    :global {
      .MuiPaper-root {
        min-width: 750px;
        margin: 1.75rem auto;
      }
    }
  }
}

.divisionFilter {
  cursor: pointer;
  position: relative;
  width: 100%;

  .total {
    position: absolute;
    right: 25px;
    width: auto;
    padding: 6px;
    background: $color-headTable;
    color: $color-blueGray;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }
}

.radioCustom {
  margin-bottom: 0;
  :global {
    .MuiTypography-root {
      font-size: 14px;
    }
  }
}
.colorApproved {
  color: $color-active;
}
.colorProcessing {
  color: $color-blue1;
}
.colorRemoved {
  color: $color-red;
}

.bgRadioProcessing,
.bgRadioHandled,
.bgRadioRemoved {
  width: 13px;
  height: 13px;
  display: inline-block;
  border-radius: 50%;
}
.bgRadioProcessing {
  background-color: $color-blue1;
}
.bgRadioHandled {
  background-color: $color-active;
}
.bgRadioRemoved {
  background-color: $color-status-removed;
}
.linkToWorkFl {
  text-decoration: none;
  color: $color-black;
  &:hover {
    text-decoration: none;
    color: $color-black;
  }
}
.floatRight {
  float: right;
}
.textEdit {
  color: #223354;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.footerModal {
  width: 100%;
  padding-left: 15px;
  margin-bottom: -15px;
  :global {
    .MuiGrid-item {
      margin-top: 0px;
      padding-top: 15px;
    }
  }
}
.modalCreateContract {
  :global {
    .MuiGrid-item {
      margin-top: 4px;
    }
  }
  .footerModal {
    width: 100%;
    padding-left: 15px;
    margin-bottom: -15px;
    :global {
      .MuiGrid-item {
        margin-top: 0px;
        padding-top: 15px;
      }
    }
  }
  .colorCheckBox {
    :global {
      .MuiTypography-root {
        font-size: 14px;
        color: $color-charcoal;
      }
    }
  }
  .onboard {
    margin-top: -20px;
  }
}
