@import "./../../styles/variable.scss";
.division {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .total {
        width: auto;
        height: fit-content;
        padding: 6px;
        background: $color-headTable;
        color: $color-blueGray;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
    }
}
