@import './../../../../styles/variable';

.dropBox {
  width: 171px;
}

.profileContentInfoAlert {
  color: $color-navActive;
  line-height: 2rem;
  font-size: 1.1rem;
  padding: 40px;
  margin-left: 40%;
}

.label {
  color: $color-nav;
  font-style: normal;
  font-weight: normal;
}
