@import '../../../../../styles//variable.scss';

:global {
  .modalContractDetail {
    .MuiPaper-root {
      width: 918px;
      max-width: unset;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .modal-body {
      padding: 0;
      max-height: unset;
    }
  }
}

.header {
  height: 67px;
  padding: 8px 20px;
  
  background-color: $bg-salmon;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headerLeft {
    display: flex;
    align-items: center;


    .headerLeftInfo {
      .username {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;
      }

      .email,
      .divisionName {
        font-size: 12px;
        color: $color-navy;
        margin-bottom: 0;
      }
    }
  }

  .headerRight {
    .btnDelete,
    .btnEdit {
      width: 124px;
      margin-left: 10px;
    }
  }
} 

.body {
  padding: 20px 21px 32px 21px;

  .contractInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
    .contractField {
      flex-basis: calc(50% - 10px);
      border-bottom: 1px solid $color-wheat;
      padding-bottom: 10px;
      margin-bottom: 10px;

      .contractFieldTitle {
        font-weight: 500;
        color: $color-titleBody;
      }

      .contractFieldValue {
        color: $color-titleModal;
      }

      .contractFieldTitle,
      .contractFieldValue {
        margin-bottom: 0;
      }
    }
  }

  .contractHistory {
    .subTitle {
      font-size: 16px;
      font-weight: 500;
      color: $color-titleBody;
    }
  }
  
  .timelineDetail {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid $color-wheat;
    background-color: $bg-rowTable;
    border-radius: 5px;
    margin: 30px 0 20px 0;
    padding: 20px 11px 10px 11px;

    .timelineField {
      flex-basis: 50%;
      margin-bottom: 10px;

      .timelineFieldTitle,
      .timelineFieldValue {
        margin-bottom: 0;
      }

      .timelineFieldTitle {
        color: $color-titleBody;
        font-weight: 500;
      }
    }
  }

  .active {
    border: 1px solid $color-active;
    background-color: $color-boxCareerActive;
  }
}
