.fieldsetCustom {
  flex-direction: unset !important;
  .radioStyleCustom {
    flex-direction: unset !important;
  }
  .labelView {
    color: unset !important;
    width: 180px;
    margin: 8px 0 0 0;
    font-size: 14px;
    font-weight: 500;
  }
  .labelRadio {
    color: unset !important;
    :global {
      span.MuiFormControlLabel-label {
        color: #8d8d8d;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
