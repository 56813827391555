@import "../../../../styles//variable.scss";

.searchComponent {
  width: 300px;
  margin-bottom: 30px;
}

.totalAsset {
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;
  justify-content: space-between;
  .title {
    font-size: 20px;
    font-weight: 500;
  }
  @media screen and (max-width: 1500px) {
    .title {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 1240px) {
    .title {
      display: flex;
      align-items: center;
      height: 50px;
    }
    .value{
      font-size: 16px !important;
    }
  }
  .itemAsset {
    padding: 10px;
    flex-basis: calc(100% / 5);
    display: flex;
    .storeItem {
      border-radius: 5px;
      padding: 15px;
      flex-grow: 1;
      .name {
        font-size: 16px;
        font-weight: 500;
      }

      .storeItemField {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .value {
          font-size: 20px;
          font-weight: 500;
        }
      }

      &:not(:last-child) {
        margin: 15px 0;
      }
    }
  }

  .all {
    padding: 15px;
    border-radius: 5px;
    background-color: $bg-magnolia1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .title {
      color: $bg-blue-violet;
    }

    .storeItem {
      background-color: $bg-blue-violet;

      .name {
        color: $bg-magnolia1;
      }

      .storeItemField {
        .label {
          color: $bg-magnolia1;
        }
        .value {
          color: $color-white;
        }
      }
    }
  }

  .waitApprove {
    padding: 15px;
    border-radius: 5px;
    background-color: #ebfff3;
    flex-grow: 1;
    .title {
      color: #2ecc71;
    }

    .storeItem {
      background-color: #2ecc71;

      .name {
        color: $bg-magnolia1;
      }

      .storeItemField {
        .label {
          color: $bg-magnolia1;
        }
        .value {
          color: $color-white;
        }
      }
    }
  }

  .cancel {
    padding: 15px;
    border-radius: 5px;
    background-color: #ffdcdc;
    flex-grow: 1;
    .title {
      color: #f24949;
    }

    .storeItem {
      background-color: #f24949;

      .name {
        color: $bg-magnolia1;
      }

      .storeItemField {
        .label {
          color: $bg-magnolia1;
        }
        .value {
          color: $color-white;
        }
      }
    }
  }

  .inUse {
    padding: 15px;
    border-radius: 5px;
    background-color: $bg-blue1;
    flex-grow: 1;
    .title {
      color: $color-blue4;
    }

    .storeItem {
      background-color: $color-blue4;

      .name {
        color: $bg-blue1;
      }

      .storeItemField {
        .label {
          color: $color-white;
        }
        .value {
          color: $color-white;
        }
      }
    }
  }

  .lost {
    padding: 15px;
    border-radius: 5px;
    background-color: $bg-yellow4;
    flex-grow: 1;
    .title {
      color: $color-yellow3;
    }

    .storeItem {
      background-color: $color-yellow3;

      .name {
        color: $bg-yellow4;
      }

      .storeItemField {
        .label {
          color: $bg-tooltip;
        }
        .value {
          color: $color-white;
        }
      }
    }
  }
}

.totalInStorage {
  background-color: $bg-white;
  border: 1px solid $color-wheat;
  border-radius: 5px;
  margin-bottom: 20px;
  padding-bottom: 1px;
  .title {
    padding: 15px;
    color: $color-navy;
    font-size: 20px;
    font-weight: 500;
  }

  .storageItem {
    margin-bottom: 20px;

    .storageName {
      padding-left: 15px;
      padding-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
      color: $color-charcoal;
    }

    .storageStatus {
      .status {
        background-color: $bg-salmon;
        color: $color-navy;
        font-weight: 500;
        padding: 1rem;
      }

      .label {
        color: $color-charcoal;
      }

      .value {
        color: $color-charcoal;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}

.chartAsset {
  border-radius: 5px;
  background-color: $bg-white;
  border: 1px solid $color-wheat;
  padding: 30px;
  margin-bottom: 20px;

  .chartTitle {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    img {
      margin-right: 10px;
    }

    .title {
      font-size: 20px;
      font-weight: 500;
      color: $color-navy;
    }
  }

  .barChart {
    height: 400px;
  }
}

.chartHireAsset {
  .barChart {
    height: 300px;
  }
}

.assetTable {
  border-radius: 5px;
  background-color: $bg-white;
  border: 1px solid $color-wheat;
  padding: 15px 0;
  height: 407px;
  .tableContainer {
    max-height: 344px;
  }
  .assetHeader {
    color: $color-black;
    line-height: 2rem;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: auto;
  }

  .assetHeaderRow {
    color: $color-titleBody;
  }

  .assetHeader tr th {
    background-color: $bg-plum !important;
    border-bottom: unset;
  }

  .tableRow {
    border-bottom: unset;
  }

  .tableRow:nth-child(odd) {
    background-color: $bg-white;
  }

  .tableRow:nth-child(even) {
    background-color: $bg-tableStripped;
  }

  .nameColumn {
    display: flex;
    .name {
      color: $color-charcoal;
      font-weight: 500;
    }

    .email {
      color: $color-nav;
      font-size: 12px;
    }
  }

  .userAvatar {
    height: 31px;
    width: 31px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 50%;
  }

  .chartTitle {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 15px;

    img {
      margin-right: 10px;
    }

    .title {
      font-size: 20px;
      font-weight: 500;
      color: $color-navy;
    }
  }
}

.strike {
  margin-top: 10px;
  margin-bottom: 13px;
  height: 0.5px;
  width: 100%;
  background-color: $bg-magnolia1;
}

.positionRight {
  position: absolute;
  right: 20px;
  top: 12px;
}
