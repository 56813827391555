@import './../../../../styles/variable';

.contract-dashboard {
  .static-employee-company {
    .info {
      font-size: 14px;
      line-height: 22px;
      color: $color-silver;
      span {
        font-weight: 500;
        font-size: 34px;
        line-height: 40px;
        color: $color-yellow;
        color: $color-yellow;
      }
    }
  }
}

.contract-dashboard-map {
  .ct-doughnut {
    margin-left: 0px;
    width: 15%;
    margin-bottom: 10px;
  }

  .ct-info-map {
    width: 85%;
  }

  .item-note {
    padding-right: 50px;
    p {
      padding-bottom: 5px;
      border-bottom: 1px solid $color-wheat;
      margin-bottom: 5px;
    }

    @media (max-width: 1024px) {
      padding-right: 10px;
    }
  }

  span {
    &.total {
      float: right;
      line-height: 22px;
      padding: 0px 8px;
      background: $bg-salmon;
      border-radius: 5px;
      color: $color-navy;
    }

    &.character {
      display: none;
    }
  }

  @media screen and (max-width: 1400px) and (min-width: 1300px) {
    .ct-doughnut {
      width: 20%;
    }

    .ct-info-map {
      width: 80%;
    }
  }

  @media (max-width: 1024px) {
    .ct-doughnut {
      margin-top: 20px;
      width: 25%;
    }

    .ct-info-map {
      width: 75%;
    }
  }
}

.contract-dashboard {
  .box-map {
    .title-body {
      margin-top: 5px;
    }
  }

  hr {
    background-color: $color-wheat;
  }

  .li-list {
    border-radius: 5px;
    color: #526487;
    .MuiListItemText-root {
      margin: 2px 0px;
    }
    .MuiTypography-root,
    .MuiListItemSecondaryAction-root {
      font-size: 14px;
      color: $color-blueGray;
    }
  }

  .box-request {
    .MuiList-root {
      padding-bottom: 0;
      margin-bottom: -5px;
    }
    .link-to-request {
      text-decoration: none;
    }
  }
}

.group-list-f3f7fb {
  .title-body {
    margin-bottom: 5px;
  }

  .pd-0 {
    padding: 0px !important;
  }

  li {
    background-color: $bg-plum;
    border-radius: 5px;
    margin-bottom: 5px;

    .MuiListItemIcon-root {
      min-width: 23px;
    }
    .MuiListItemText-root {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $color-blueGray;
    }
  }

  img {
    width: 15px;
    height: 15px;
  }
}
