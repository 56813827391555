@import '../../styles/variable.scss';

@mixin boxBody {
  display: flex;
  align-items: center;
  position: relative;
  height: 45px;
  width: 52%;
  min-width: 125px;
  background-color: $color-white;
  border-radius: 999px;

  &:hover {
    cursor: pointer;
  }
}

.box {
  height: 100px;
}

.box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  .beforeLine {
    height: 1px;
    flex-grow: 1;
    min-width: 40px;
    background-color: $color-grey1;
  }

  .afterLine {
    height: 1px;
    min-width: 30px;
    width: 40px;
    background-color: $color-grey1;
  }

  .verticalLine {
    height: 100%;
    width: 1px;
    background-color: $color-grey1;
    transform: translateY(50px);
  }

  .totalItemInLine {
    color: $color-white;
    background-color: $color-yellow;
    position: absolute;
    right: -18px;
    z-index: 1;
    border-radius: 2px;
    width: 35px;
    text-align: center;
  }

  .highlineHeight {
    height: 2px;
    background-color: $color-yellow;
  }

  .highlineWidth {
    width: 2px;
    background-color: $color-yellow;
  }

  .boxBody,
  .boxBodyAddNewItem {
    @include boxBody();

    .totalItem,
    .addIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 41px !important;
      height: 39px !important;
      border: 1px solid $color-grey1;
      color: $color-silver;
      border-radius: 50%;
      margin-left: 1px;
    }

    .addIcon {
      color: $color-yellow;
      font-size: 200%;
      background: $color-white;
    }

    .name,
    .addTitle {
      max-width: 120px;
      font-size: 12px;
      line-height: 1.1;
      text-align: center;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $color-nav;
      margin: auto;
      padding-right: 8px;
    }

    .active{
      font-weight: 500;
      color: $color-navActive;
    }

    .addTitle {
      margin: auto;
    }

    .btnDropdown {
      button {
        padding: 0;
        margin-right: 3px;
        background-color: unset;
        color: $color-black;
        border: unset;
      }

      .iconDropdown {
        transform: rotate(90deg);
      }

      .dropdownItem {
        padding: 5px 10px;
        color: $color-charcoal;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
      }
    }

    .code {
      position: absolute;
      top: -10px;
      right: 20px;
      font-size: 12px;
      color: $color-white;
      background: $color-grey1;
      height: 17px;
      min-width: 45px;
      text-align: center;
      border-radius: 6px;
      padding: 0 4px;
    }
  }

  .boxBody {
    justify-content: space-between;
    border: 2px solid $color-grey1;
  }

  .boxBodyAddNewItem {
    border: 1px dashed $color-nav;
  }
}

.accordionBox {
  position: relative;
  display: flex;

  .beforeLine {
    height: 1px;
    flex-grow: 1;
    min-width: 60px;
    margin-top: 50px;
    background-color: $color-grey1;
  }

  .highlineHeight {
    height: 2px;
    background-color: $color-yellow;
  }

  .boxBody {
    width: 90%;
    background-color: $bg-white;
    padding: 0 20px;
    border: 1px solid $color-grey;
    border-radius: 5px;
    .divisionHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $color-grey;
      padding: 18px 0 12px 0;
      cursor: pointer;

      .divisionInfo {
        display: flex;
        align-items: center;
        width: 45%;

        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          margin-right: 9px;
          border-radius: 50%;
        }
      }

      .role {
        display: flex;
        flex-wrap: wrap;
        max-width: 50%;

        .roleItem {
          flex-basis: calc(100% / 3);
          color: $color-charcoal;
        }
      }
    }

    .divisionBody {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid $color-grey;
      display: none;

      .memberInfo {
        display: flex;
        align-items: center;
        flex-basis: 50%;
        margin: 12px 0;

        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          margin-right: 9px;
          border-radius: 50%;
        }
      }
    }
  }
}

.hidden {
  visibility: hidden;
}

.visibility {
  display: flex !important;
}

:global {
  .department-tooltip {
    .tooltip-inner {
      background-color: $bg-plum;
      color: $color-charcoal;

      .division-head {
        color: $color-navy;
        font-weight: 500;
      }

      p {
        text-align: left;
        margin-bottom: 0;
        font-size: 10pt;

        span {
          color: $color-black1;
          font-weight: 500;
        }
      }
    }

    .tooltip .arrow {
      display: none;
    }
  }
}
