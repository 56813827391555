@import "./../../styles/variable.scss";
.boxMap {
  display: flex;
  flex-grow: 1;
  .mapDoughnut {
    position: relative;
    width: 160px;
    height: 160px;
    margin-right: 40px;
    margin-left: 40px;
  }

  @media (max-width: 1060px) {
    .mapDoughnut {
      margin-right: 15px;
      margin-left: 0px;
    }
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .infoMap {
    flex-grow: 1;
    .itemNote {
      width: 100%;
      float: left;
      font-size: 14px;
      line-height: 22px;
      color: $color-charcoal;

      .bgRadio {
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 42px;
        margin-right: 10px;
      }

      &:last-child {
        p {
          border-bottom: 1px solid $color-white !important;
        }
      }
    }

    @media (min-width: 1360px) {
      .itemNote {
        width: 50%;
      }
    }
  }

  @media (max-width: 1024px) {
    .infoMap {
      padding-left: 15px;
    }
  }

  .notBorder {
    @media (min-width: 1360px) {
      p {
        border-bottom: 1px solid $color-white !important;
      }
    }
  }
}
.total {
  position: absolute;
  top: 60px;
  right: 50%;
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  color: #bdbdbd;
  transform: translateX(50%);
}
