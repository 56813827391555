@import "../../../styles/variable.scss";

.search {
  display: flex;
  justify-content: flex-end;
}

.dragable {
  display: none;
}

.visiable {
  display: block;
}

.moduleItem {
  position: relative;

  .moduleItemHeader {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    img {
      cursor: pointer;
    }

    .line {
      width: 5px;
      height: 45px;
      background-color: $color-navy;
      border-radius: 4px;
      margin: 0 10px;
    }

    .content {
      color: $color-navy;
      font-size: 34px;
      font-weight: 500;
    }
  }
}

.blockItem {
  display: flex;
  flex-direction: column;
  background-color: $bg-white;
  border-radius: 5px;

  .blockItemHeader {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .blockItemHeaderLeft {
      display: flex;

      img {
        margin-right: 10px;
      }
    }
  }
}

:global {
  .modalSearchDashboard {
    .MuiPaper-root {
      width: 85%;
      max-width: 1100px !important;

      .modal-body {
        padding: 1.5rem 6rem;
      }
    }
  }
}

.filterResult {
  .menu {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    .menuItem {
      margin: 0 15px;
      cursor: pointer;
    }

    .active {
      color: $color-yellow;
    }
  }

  .searchItem {
    background-color: $bg-white;
    border-radius: 5px;
    border: 1px solid $color-box-shadow;
    padding: 0 20px;
    margin-bottom: 20px;
    box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

    .header {
      padding: 10px 0px;
      color: $color-navy;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid $color-wheat;
    }

    .body {
      padding: 20px 0;

      .employees {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-template-rows: auto;
        .employee {
          display: flex;
          margin-bottom: 30px;
          @media (max-width: 1024px) {
            width: 50%;
          }

          img {
            margin-right: 10px;
            height: 70px;
            width: 70px;
            object-fit: cover;
            border-radius: 50%;
          }

          .name {
            color: $color-navy;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 170px;
          }

          .position,
          .email {
            color: $color-nav;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 170px;
          }
        }
      }

      .events {
        display: flex;
        margin: 0 -10px;
        // max-height: 350px;

        .wrapGutter {
          padding: 0 10px;
        }

        .event {
          flex-basis: calc(100% / 3);
          border-radius: 5px;
          border: 1px solid $color-wheat;
          overflow: hidden;

          img {
            width: 100%;
            height: 170px;
            object-fit: cover;
          }

          .body {
            padding: 10px;

            .time {
              color: $color-nav;
              margin-bottom: 3px;
            }

            .title {
              font-size: 16px;
              font-weight: 500;
              color: $color-navy;
              margin-bottom: 4px;
            }

            .numberRegister {
              color: $color-grey1;
              margin-bottom: 10px;
            }

            .btnRegister {
              display: flex;
              justify-content: center;
            }
          }
        }
      }

      .assets {
        display: flex;
        flex-wrap: wrap;
        // max-height: 450px;

        .asset {
          flex-basis: 50%;
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          img {
            margin-right: 20px;
          }

          .name {
            font-size: 16px;
            font-weight: 500;
            color: $color-navy;
          }

          .status {
            color: $color-grey1;
          }

          .value {
            font-size: 20px;
            font-weight: 500;
            color: $color-yellow;
          }
        }
      }

      .divisions {
        display: flex;
        flex-wrap: wrap;
        // max-height: 200px;

        .division {
          // flex-basis: calc(100% / 3);
          display: flex;
          margin-bottom: 30px;
          width: 33.3333%;
          @media (max-width: 1024px) {
            width: 50%;
          }

          img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            margin-right: 10px;
            border-radius: 50%;
          }

          .name {
            color: $color-navy;
            font-weight: 500;
          }

          .manager {
            color: $color-grey1;

            .user {
              text-decoration: underline;
            }
          }

          .totalMember {
            color: $color-grey1;
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      padding: 20px 0;
      margin-top: -20px;

      :global {
        .btnViewAll {
          width: 50%;
          background-color: $color-navy;
          color: $color-white;
        }
      }
    }

    .status {
      .type {
        text-transform: lowercase;
      }
    }
  }
}

.link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.blockEmployee {
  width: 30%;
}
