@import "./../../../../styles/variable.scss";
.loading {
  margin-left: 35%;
}
.employee {
  margin-top: -50px;
  input[type="text"] {
    color: $color-black;
  }
  .stickyScroll {
    position: sticky;
    z-index: 333;
    top: 12%;
  }
  .columnFilter {
    background: $bg-white;
    border: 1px solid $color-wheat;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 15px 30px;
    height: fit-content;
    box-shadow: 0px 5px 25px $color-box-shadow;
    .totalEmployee {
      cursor: pointer;
      padding: 6px 15px;
      border-radius: 5px;
      margin-bottom: 10px;
      span.textTotal {
        margin-left: 5px;
        color: $color-navActive;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        margin-top: 6px;
      }
      span.total {
        position: absolute;
        right: 47px;
        color: $color-navActive;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-top: 8px;
      }
      img {
        width: 25px;
      }
    }
    .selected {
      background: $color-totalEmployee;
    }
    .notSelected {
      background: $color-totalNotCompleted;
    }
    .exampleSelectMulti {
      appearance: none;
      margin-bottom: 15px;
      cursor: pointer;
      border: 1px solid $color-inputColor;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: $color-white;
      color: $color-input-modal;
    }
    .searchInput {
      margin: 0 0 0 0;
      box-shadow: none;
    }
    .rowListRight {
      background: $bg-white;
      border: 1px solid $color-borderTable;
      box-sizing: border-box;
      padding: 10px;
      padding-top: 15.5px;
      border-right: none;
      border-left: none;
      border-bottom: none;
      .listRight {
        width: -webkit-fill-available;
        margin: 0 0 0 0;
        color: $color-black;
        li {
          margin-bottom: 20px;
        }
        .division {
          cursor: pointer;
          display: flex;
          align-items: center;
          .itemRadio {
            padding-right: 30px;
            cursor: pointer;
            color: $color-black;
            margin-left: 35px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
          }
          .total {
            position: absolute;
            right: 30px;
            padding: 6px;
            background: $color-headTable;
            color: $color-blueGray;
            border-radius: 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
          }
          input[type="radio"].form-check-input {
            margin: 4px 10px 0 0px;
            position: inherit;
          }
          input[type="radio"] {
            margin: 8px 0 0 0;
          }
        }
        .titleFilter {
          color: $color-navActive;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
        img.radio {
          width: 20px;
        }
      }
    }
    .reset {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 22px 32px;
      margin-top: 10px;
      font-size: 14px;
      height: 36px;
      background: $color-navActive;
      border-radius: 5px;
      border: none;
      color: $color-white;
      .imgReset {
        margin: "0 10px 0 0";
      }
      &:hover {
        background: $color-navActive;
        color: $color-white;
      }
      &:active {
        background: $color-blue !important;
        color: $color-white !important;
      }
    }
    .searchFilter {
      border-top: 1px solid $color-borderTable;
      padding-top: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      .formRight {
        width: 100%;
        .iconSearch {
          color: $color-menuActive !important;
        }
      }
    }
    .iconStatistical {
      color: $color-white;
      font-size: 30px;
      font-weight: 300;
      margin: -10px 8px 0 0;
    }
  }
  .navTabs {
    border: none;
    a.active.navLink {
      cursor: pointer;
      border: none;
      border-bottom: 1px solid $color-buttonSubmit;
      color: $color-navActive;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
    a.navLink {
      cursor: pointer;
      color: $color-navActive;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      cursor: pointer;
    }
    a {
      font-weight: 500;
    }
  }

  .exportAround {
    margin-bottom: 25px;
    .marginLeft {
      z-index: -1;
    }
    .ctButton {
      align-items: center;
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $color-white;
      background-color: $bg-yellow1;
      border-radius: 5px;
      padding: 7px 35px 7px 32px;
      border: 1px solid $bg-yellow1;
      margin-left: 2px;
      &:active {
        background-color: $color-buttonSubmitActive !important;
        color: $bg-body !important;
      }

      &:hover {
        background-color: $bg-white;
        color: $bg-yellow1;
        border: 1px solid $bg-yellow1;
      }

      img {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
      :global {
        svg {
          font-size: 25px;
        }
      }

      &.iconButtonExport {
        svg {
          margin-right: 5px;
        }
      }
    }
    .addEmployAndImport {
      margin-right: 5px !important;
    }
    .first {
      width: 35%;
    }
    .textExport {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin-left: 10px;
    }
    .exportRequest {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      img {
        width: 13px;
      }
      .linkRequest {
        margin-right: 5px !important;
        text-decoration: none;
      }
    }
  }
  table {
    width: 125%;
    color: $color-black;
    background: $color-table;
    border: 1px solid $color-borderTable;
    box-sizing: border-box;
    border-radius: 5px;
    @media screen and (max-width: 1200px) {
      min-width: 300% !important;
    }
    @media (min-width: 1200px) and (max-width: 1850px) {
      min-width: 200% !important;
    }
    @media (min-width: 1850px) and (max-width: 2000px) {
      min-width: 150% !important;
    }
    .linkRequest {
      color: #333333;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-decoration: none;
      word-break: break-all;
    }
    tr.headTable {
      background-color: $color-headTable !important;
      td.fixed {
        padding-right: 30px;
        left: -1px;
        background-color: $color-headTable !important;
        position: sticky;
        position: -webkit-sticky;
        z-index: 1;
      }
      td.fixedName {
        padding-right: 30px;
        left: 35px;
        background-color: $color-headTable !important;
        position: sticky;
        position: -webkit-sticky;
        z-index: 1;
      }
      td {
        padding: 16px 8px;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.5rem;
      }
    }
    tr:nth-child(odd) {
      background-color: $bg-white;
      td.fixed {
        left: -1px;
        background-color: $bg-white;
        position: sticky;
        position: -webkit-sticky;
        z-index: 1;
      }
      td.fixedName {
        left: 35px;
        background-color: $bg-white;
        position: sticky;
        position: -webkit-sticky;
        z-index: 1;
      }
    }
    tr:nth-child(even) {
      background-color: $bg-tableStripped;
      td.fixed {
        left: -1px;
        background-color: $bg-tableStripped;
        position: sticky;
        position: -webkit-sticky;
        z-index: 1;
      }
      td.fixedName {
        left: 35px;
        background-color: $bg-tableStripped;
        position: sticky;
        position: -webkit-sticky;
        z-index: 1;
      }
    }
    td.title {
      font-size: 14px;
      font-weight: 600;
    }

    td.center {
      text-align: center;
    }

    td {
      max-width: 200px;
      vertical-align: middle;
      color: $color-navActive;
      .checked {
        cursor: pointer;
        margin: 0;
        margin-top: -8px;
        background-color: $color-white;
        font: caption;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid $color-silver;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
      }
      p.email {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin: 0 0 0 0;
        color: $color-email;
      }
      span.active {
        color: $color-active;
      }
      span.inActive {
        color: $color-red;
      }
    }
  }
}

.modalCommon {
  color: $color-black;
  .modalTitle {
    background: $color-titleModal;
    border-radius: 5px 5px 0px 0px;
    width: auto;
    height: 50px;
    h5 {
      color: $bg-body;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 21px;
      margin: -5px 0 0 0;
    }
    span {
      height: 30px;
      width: 30px;
      color: #fea628;
      border: 1px solid #223354;
      box-sizing: border-box;
      text-align: center;
      padding-top: 5px;
      margin-top: -12px;
      padding-top: 2px;
      border-radius: 50%;
      display: inline-block;
    }
  }
  .modalBody {
    padding: 0;
    .itemCheck {
      width: -webkit-fill-available;
      cursor: pointer;
      padding: 18px;
      .checked {
        position: absolute;
        right: 30px;
        width: 18px;
      }
      .checkFieldExport {
        display: none;
      }
      p.exportTitle {
        color: $color-titleExport;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 0 0;
      }
      p.title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $color-navy !important;
      }
    }
    .activeExport {
      background: $color-exportActive;
    }
    .hrModal {
      margin: 0 auto;
      width: 93%;
      background: $bg-body;
      mix-blend-mode: normal;
    }
    img.radio {
      width: 20px;
      margin-right: 10px;
    }
  }
  .modalFooter {
    justify-content: center;
    .buttonSubmit {
      width: -webkit-fill-available;
      background: $color-buttonSubmit;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      height: 36px;
      color: $color-white;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      border: none;
      &:active {
        background-color: $color-buttonSubmitActive !important;
        color: $color-white !important;
      }
      &:hover {
        background: $color-buttonSubmit;
        color: $color-white;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .employee {
    .exportAround {
      margin-bottom: 20px;
    }
  }
  :global {
    .wrapper-top {
      margin-top: 2px !important;
    }
  }
}

:global {
  .modal-body {
    max-height: 500px;
    overflow: auto;
  }
  .modal-body::-webkit-scrollbar {
    width: 4px;
  }
  .modal-body.thin::-webkit-scrollbar {
    width: 2px;
  }
  .modal-body::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-white;
  }
  .modal-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $bg-yellow1;
  }
  .wrapper-top,
  .wrapper-bottom {
    width: 100%;
    overflow-x: scroll;
    cursor: pointer;
    .top {
      margin-top: -20px;
    }
  }
  .wrapper-bottom {
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    box-shadow: 0px 5px 25px $color-box-shadow;
    border-radius: 5px;
  }
  .wrapper-top {
    margin-top: -8px;

    p {
      font-size: 16px;
    }
  }
  .wrapper-top::-webkit-scrollbar,
  .wrapper-bottom::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
  .wrapper-top.thin::-webkit-scrollbar,
  .wrapper-bottom.thin::-webkit-scrollbar {
    width: 2px;
  }
  .wrapper-top::-webkit-scrollbar-track,
  .wrapper-bottom::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-white;
  }
  .wrapper-top::-webkit-scrollbar-thumb,
  .wrapper-bottom::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-navActive;
  }
  .perPage {
    margin-bottom: 5% !important;
    width: 100%;
    margin: 0 auto;
    height: 50px;
    border: 1px solid $color-borderTable;
    box-sizing: border-box;
    border-top: none;
    box-shadow: 0px 5px 25px $color-box-shadow;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    .col-sm-6 {
      border-bottom-right-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
      background-color: $color-white;
      p {
        margin-top: 12px;
      }
    }
    select {
      background-color: white !important;
    }
  }
  .edit-infor-user {
    .fieldset-group.select {
      max-width: 210px;
      height: 55px;
    }
    form {
      margin-top: 0 !important;
      .select-field {
        min-width: 200px;
      }
      label.form_label_date {
        position: absolute;
        top: -20%;
        left: 4%;
        z-index: 1;
        background: white;
        font-size: 0.8rem;
      }
      .btnDeleted {
        padding: 0px;
        svg {
          padding-top: 3px;
          font-size: 20px !important;
        }
      }
      .certificationBoxNew {
        padding: 0 !important;
        height: 86px !important;
        overflow: hidden;
        .imgCertificate {
          height: 86px !important;
          width: 155px !important;
          img {
            object-fit: cover;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          }
        }
        .profileContentInfo {
          line-height: 20px !important;
          font-size: 12px;
          padding-left: 15px !important;
          &:first-child {
            margin-top: 10px;
            line-height: 22px !important;
            font-size: 14px !important;
          }
        }
      }
    }
  }
  .import-user {
    .MuiPaper-root {
      max-width: 500px !important;
      .modal-body {
        max-height: 400px;
        overflow: scroll;
        .progress-import {
          width: 100% !important;
          margin-right: 0 !important;
        }
        .infor {
          position: absolute;
          z-index: 1;
          color: $color-grey1;
          font-size: 14px;
        }
        .iconImport {
          height: 20px;
          width: 25px;
          left: 48%;
          top: 13px;
        }
        .notifi {
          left: 38%;
          top: 40px;
        }
        .format {
          left: 39%;
          top: 60px;
        }
        .download-template {
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-decoration-line: underline;
          color: #0085ff;
          margin-bottom: 20px;
          cursor: pointer;
        }
      }
      .modal-body::-webkit-scrollbar {
        width: 2px;
      }
      .modal-body.thin::-webkit-scrollbar {
        width: 2px;
      }
      .modal-body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $bg-yellow1;
      }
    }
  }
}
img.radio {
  width: 20px;
  margin: 0 12px 0 0;
}
.cusor {
  cursor: pointer;
}
img.radio.right {
  margin: 0 12px 0 25px;
}
.gender {
  width: -webkit-fill-available;
}
.import {
  margin-top: 0;
  .labelImport {
    width: -webkit-fill-available;
    position: relative;
    top: 0;
    left: 0;

    .imgImport {
      width: -webkit-fill-available;
      height: 100px;
      cursor: pointer;
    }
  }
}
.progress {
  position: absolute;
  right: 6%;
}
.error {
  background: $bg-errorImport;
  border-radius: 5px;
  padding: 10px 35px 10px 15px;
  margin-top: 10px;
}
.fileInvalid {
  height: 15px;
  margin-right: 5px;
}
.inforImport {
  color: #333333;
  font-weight: 500;
}

.groupButton {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  height: 40px;

  .ctButton {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $color-white;
    background-color: #fea628;
    border-radius: 5px;
    padding: 7px 32px;
    box-shadow: none;
    border: 1px solid #fff8ee;
    white-space: nowrap;
    margin-left: 10px;
    height: 100%;

    svg {
      font-size: 20px;
      margin-right: 5px;
    }

    &:hover,
    &:active {
      background-color: $color-white !important;
      color: #fea628 !important;
      border: 1px solid #fea628 !important;
    }
  }
}
.formInput {
  border: 1px solid #eee;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  padding: 0 12px;
  transition: 0.25s ease;
}

.formInput:focus,
.formInput:hover {
  border-color: $color-green;
}

.fieldError {
  border: 1px solid red;
}

.fieldError:focus,
.fieldError:hover {
  border-color: red;
}

.formField {
  position: relative;
  &:hover {
    label {
      color: $color-green;
    }
  }
}

.formField {
  position: relative;
  :global {
    .react-datepicker-popper {
      z-index: 10;
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
      left: -15px;
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
      left: -15px;
    }
  }
}

.formLabel {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
  user-select: none;
  color: #999;
  pointer-events: none;
  transition: 0.25s ease;
}
.formInput:focus,
.focus-visible:focus:not(:focus-visible) {
  outline: 0;
}

.formInput:focus + .formLabel {
  top: 0;
  left: 11px;
  padding: 0 10px;
  display: inline-block;
  background-color: $color-white;
  color: $color-green;
}

.labelError{
  color: red !important;
}

.inputFocusCustom {
  top: 0;
  left: 11px;
  padding: 0 10px;
  display: inline-block;
  background-color: $color-white;
  color: $color-green;
}
.errorBirthday {
  margin-left: 10px;
  margin-top: 5px;
}
