@import "styles/variable.scss";

.list-time-sheets {
  margin-bottom: 100px;

  .button {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    height: 40px;
    margin-top: -5px;
  }

  .send-mail {
    padding: 9px 0 7px 0;
    margin-left: 10px;
    width: 150px;

    svg {
      transform: rotate(90deg);
      margin-bottom: 4px;
    }

    img {
      width: 21px !important;
      height: 18px !important;
    }
  }

  .lock-table-timesheet {
    padding: 9px 0 7px 0;
    margin-left: 10px;
    width: 150px;

    svg {
      margin-bottom: 4px;
    }

    img {
      width: 16px !important;
      height: 21px !important;
    }
  }

  .unlock-table-timesheet {
    background-color: #FFF8EE;
    color:#FEA628;
  }

  .create-request {
    padding: 9px 0 7px 0;
    margin-left: 10px;
    width: 150px;

    svg {
      margin-top: -3px;
    }
  }

  .create {
    margin-left: 10px;
    width: 178px;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  .export {
    margin-left: 10px;
    width: 150px;

    svg {
      margin-bottom: 3px;
    }
  }

  .padding-left {
    padding-left: 10px;
  }

  .error-no-data {
    text-align: center;
    color: $color-red;
    padding: 20px 0;
  }

  .flex {
    display: flex;
  }

  .center {
    text-align: center;
    padding: 20px;
  }

  .button {
    display: flex;
    justify-content: flex-end;
    height: 40px;
    margin-top: -5px;
  }

  .table-list {
    border: unset;
  }

  .division {
    cursor: pointer;
    position: relative;
    width: 100%;
    display: flex;

    .dot-status-accept {
      width: 13px;
      height: 13px;
      border-radius: 13px;
      margin-top: 15px;
    }

    .color-blue {
      background-color: $color-blue1;
    }

    .color-green {
      background-color: $color-active;
    }

    .total {
      position: absolute;
      right: 0px;
      width: auto;
      padding: 6px;
      background: $color-headTable;
      color: $color-titleBody;
      border-radius: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .email {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 0 0;
    color: $color-email;
    word-break: break-word;
  }

  .lack-of-time {
    color: $color-red;
  }

  .profile-box {
    margin-top: 20px;

    .long {
      width: 9%;
    }

    .center {
      text-align: center;
    }

    .short {
      width: 80px;
    }

    .asset-header {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;
      cursor: auto;
    }

    .asset-header-row {
      background-color: $color-headTable !important;
    }

    :global {
      .MuiTableCell-root {
        text-align: left;
      }
    }

    .asset-content {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;

      td {
        cursor: pointer;
      }
    }

    .asset-header tr {
      background-color: $color-headTable !important;
    }

    tr:nth-child(odd) {
      background-color: $bg-white;
    }

    tr:nth-child(even) {
      background-color: $bg-tableStripped;
    }
  }

  .profile-box {
    padding: 0;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;

    :global {
      .MuiTableCell-root {
        white-space: normal !important;
        word-wrap: break-word !important;
      }

      .css-15wwp11-MuiTableHead-root {
        .MuiTableCell-root {
          color: $color-titleBody;
          font-weight: 500;
        }
      }
    }

    :global {
      .css-o9ey65 {
        position: relative;

        .MuiTablePagination-displayedRows.css-1chpzqh {
          position: absolute;
          left: 17px;
        }

        .MuiTablePagination-actions {
          .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
            color: $color-menuActive;
            font-size: 30px;
          }
        }
      }
    }

    table {
      margin: 0;
      cursor: pointer;
      table-layout: fixed;

      .profile-content-info {
        word-wrap: break-word;
        font-weight: normal;
      }

      .thead {
        background-color: $color-headTable;

        .profile-content-info {
          color: $color-titleBody;
        }
      }

      tr {
        font-weight: 500;
        color: $color-charcoal;
      }
    }

    @media only screen and (max-width: 1650px) {
      .table-scroll {
        overflow-x: scroll;
        cursor: pointer;
        width: 190%;
      }
    }

    .scroll-custom::-webkit-scrollbar {
      width: 2px;
      height: 5px;
    }

    .scroll-custom::-webkit-scrollbar {
      width: 2px;
    }

    .scroll-custom::-webkit-scrollbar-track {
      border-radius: 10px;
      background: $color-white;
    }

    .scroll-custom::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $color-navActive;
    }

    .create-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 17px 0;
      height: 30px;
      background: $color-navActive;
      border-radius: 5px;
      color: $color-white;
      width: 95px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      .text-button {
        width: 70px !important;
      }

      .img-reset {
        margin: "0 10px 0 0";
      }

      &:hover {
        background: $color-navActive;
        color: $color-white;
      }

      &:active {
        background: $color-blue !important;
        color: $color-white !important;
      }
    }
  }

  .box-waiting-content.second {
    padding-top: 0;
  }

  .box-waiting-content {
    padding: 18px 0 15px 20px;
    display: flex;

    .content {
      padding-top: 18px;
      display: flex;
      width: 95%;
      border-top: 1px solid #e7e7e7;
    }

    img {
      width: 23px;
      height: 23px;
      margin-right: 10px;
    }

    img.total-assets {
      width: 29px !important;
      height: 29px !important;
    }

    .box-waiting-text {
      .waiting-text {
        color: $bg-nav;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
      }

      .waiting-number {
        color: $color-menuActive;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
      }
    }
  }

  .box-filter {
    background: $bg-tooltip;
    margin: 19px 0 0 23px;
    height: auto;
    padding: 15px;
    border: 1px solid $color-wheat;
    box-sizing: border-box;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;

    .selected {
      background: $color-totalEmployee;
    }

    .not-selected {
      background: $bg-thTable1;
    }

    .notSelected {
      background-color: $color-totalNotCompleted;
    }

    .select-field {
      border-radius: 5px;
      padding: 15px;
      display: flex;
      position: relative;
      cursor: pointer;
      margin-bottom: 5px;

      .img-filter {
        width: 20px;
        height: 20px;
      }

      .select-field-title {
        margin-left: 15px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $color-navActive;
        word-wrap: break-word;
      }

      .select-field-total {
        position: absolute;
        right: 17px;
        color: $color-navActive;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }

    .padding-element-filter {
      padding-bottom: 20px;

      .exampleSelectMulti {
        appearance: none;
        width: 100%;
        cursor: pointer;
        border: 1px solid $color-inputColor;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: $color-white;
        justify-content: center;
        align-items: flex-start;
        padding: 5px 0;
        padding-left: 13px;
        height: 40px;
        color: $color-truncate;
      }

      .exampleSelectMulti:hover {
        cursor: pointer;
      }
    }

    .none-bottom {
      padding-bottom: 0 !important;
    }

    .padding-element-top {
      padding-top: 20px;
    }

    .margin-element-bottom {
      margin-bottom: 30px;
    }

    .border-top {
      border-top: 1px solid $color-borderTable;
    }

    .text {
      color: $color-titleBody;
      font-weight: 500 !important;
      font-size: 16px;
    }

    .division {
      cursor: pointer;
      position: relative;
      width: 100%;
      display: flex;

      .dot-status-accept {
        width: 13px;
        height: 13px;
        border-radius: 13px;
        margin-top: 15px;
      }

      .color-blue {
        background-color: $color-blue1;
      }

      .color-green {
        background-color: $color-active;
      }

      .total {
        position: absolute;
        right: 0px;
        width: auto;
        padding: 6px;
        background: $color-headTable;
        color: $color-titleBody;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
    }

    .reset {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 22px 32px;
      margin-top: 10px;
      height: 36px;
      background: $color-navActive;
      border-radius: 5px;
      color: $color-white;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      .img-reset {
        margin: "0 10px 0 0";
      }

      &:hover {
        background: $color-navActive;
        color: $color-white;
      }

      &:active {
        background: $color-blue !important;
        color: $color-white !important;
      }
    }

    li {
      padding-bottom: 25px;
    }
  }

  .link-request {
    color: $color-titleExport;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none;
  }

  .email {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 0 0;
    color: $color-email;
  }

  .color-waiting {
    color: $color-blue1;
  }

  .color-deny {
    color: $bg-menuNumberInfo;
  }

  .color-accept {
    color: $color-active;
  }

  .info-detail {
    display: flex;
    padding: 23px 20px 20px 20px;
    background-color: $color-headTable;

    .status-info {
      line-height: 20px;
    }

    .avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    .info {
      width: 100%;
    }

    .link-request {
      line-height: 18px !important;
    }

    .email {
      line-height: 18px !important;
    }
  }

  .info-profile {
    padding: 15px 25px;

    .info-profile-element {
      padding: 20px 0;
      margin-right: 20px;
      border-bottom: 1px solid $color-borderTable;

      .info-profile-label {
        color: $color-titleBody;
        font-weight: 500;
      }

      .info-profile-content {
        color: $color-charcoal;
        font-weight: normal;
      }
    }
  }

  .selectField {
    border-radius: 5px;
    padding: 15px;
    display: flex;
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;

    .imgFilter {
      width: 20px;
      height: 20px;
    }

    .selectFieldTitle {
      margin-left: 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $color-navActive;
      word-wrap: break-word;
    }

    .selectFieldTotal {
      position: absolute;
      right: 17px;
      color: $color-navActive;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .marginElementBottom {
    margin-bottom: 30px;
  }
}

.send-mail-modal {

  .text-template-table {
    padding-bottom: 20px;
  }


  .btn-send-mail {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .button-cancel-custom {
      width: 25%;
    }
    .button-submit-custom {
      width: 25%;
    }
  }

  table {
    .MuiTableCell-root {
      padding-left: 35px !important;
      padding-right: 35px !important;
    }
    th {
      color: $color-black;
      padding: 16px !important;
    }
    td {
      cursor: pointer;
    }
    .id-standard {
      color: $color-black !important;
    }
    .thead {
      background-color: $color-headTable;

      .profile-content-info {
        color: $color-titleBody;
      }
    }

    tr {
      font-weight: 500;
      color: $color-charcoal;
      cursor: pointer;
    }
    tr.headTable {
      font-weight: 500;
      color: $color-black;
      background-color: $color-headTable !important;
    }
    tr:nth-child(odd) {
      background-color: $bg-white;
    }
    tr:nth-child(even) {
      background-color: $bg-tableStripped;
    }
  }
  .MuiPaper-root {
    min-width: 918px;
  }


  .modal-body {
    padding: 0px !important;
    max-height: 900px;
  }

  .background-header {
    background-color: $bg-nav;
  }

  .text-header-create-JD {
    padding-top: 5px !important;
    font-size: 20px !important;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 23px;
    color: $color-navy;
  }

  .header {
    height: 192px;
    background: $bg-salmon;
    padding: 20px 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 20px;
  }

  .header-left {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  .button-cancel-custom {
    margin-right: 20px;
    width: 92px;
    padding: 7px 23px;
  }

  .button-submit-custom {
    width: 92px;
    text-align: center;
    padding: 7px 23px;
  }

  .modal-body-custom {
    padding: 1.5rem;
  }

  .modal-radio-managerJD {
    padding-left: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }

  .modal-status {
    margin-top: 30px;
  }

  .modal-status-custom {
    margin-right: 153px;
  }

  .text-header-body-modal {
    font-family: 'Roboto';
    color: $color-navy;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    // padding-bottom: 20px;
  }

    .content-email-modal {
      padding: 20px;
  
      .ck-content {
        min-height: 300px !important;
      }
    }

  .text-modal-content-email {
    padding-bottom: 20px;
  }

  .footer-email-modal {
    padding: 20px;
  }

  .text-info-body-modal {
    font-family: 'Roboto';
    color: $color-navy;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .information {
    border-bottom: 1px solid $color-borderTable;
  }
}

.w150{
  width: 150px;
}

.w100 {
  width: 100px;
}
