.commingSoon {
  padding: 24px 24px 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }

  .button {
    margin-top: 12px;

    &__ok {
      color: #FEA628;
    }
  }
}
