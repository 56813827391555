@import "./../../../../styles/variable";
.certificate.nav.nav-tabs {
    border: none;

    a.active.nav-link {
        border-bottom: 3px solid $color-menuActive !important;
        color: $color-navActive !important;
    }

    a.nav-link {
        border: none !important;
        font-weight: 500;
        font-size: 17px;
        color: $color-nav;
        line-height: 19px;
        box-sizing: border-box;
        text-transform: uppercase;
        background: transparent;
    }
}

.navbar-master-data-certificate {
    display: flex;
    list-style: none;
    padding-left: 10px;
    margin-bottom: 30px;

    .link {
        color: $color-nav;
        font-size: 17px;
        line-height: 24px;
        margin-right: 10px;
        line-height: 19px;
        padding-bottom: 10px;
        text-transform: uppercase;
        text-decoration: none;
        .navItem {
            padding: 0 10px;
        }
    }
    .isActive {
        color: $color-navActive;
        border-bottom: 2px solid $color-menuActive;
    }
}
