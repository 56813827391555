.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;
}

.h-100 {
  height: 100%;
}
