@import 'styles/variable.scss';

.side-tab-menu {
  align-items: center !important;
  .Mui-selected {
    color: $color-navActive !important;
  }

  .MuiTabs-indicator {
    background-color: $color-menuActive !important;
  }

  .MuiTabs-scrollButtons {
    border-radius: 50%;
    border: 1px solid $color-wheat;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    color: $color-silver;

    &:nth-child(1) {
      margin-right: 10px;
      margin-left: 0;
    }
  }
}
