@import "styles/variable.scss";

.sendMailModal {
  padding: 1.5rem;

  :global {
    .MuiPaper-root {
      min-width: 918px;
    }
    .modal-body {
      padding: 0px;
      max-height: 900px;
    }
  }
}

.header {
  background: $bg-salmon;
  padding: 20px 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 20px;

  .titleHeader {
    font-family: 'Roboto';
    color: $color-navy;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .titleHoursOT {
    @extend .titleHeader;
    line-height: 22px;
    font-size: 14px;
    margin-top: 10px;
  }
}

.mainContent {
  padding: 20px;

  :global {
    .ck-content {
      min-height: 300px !important;
    }
  }

  .textContent {
    font-family: 'Roboto';
    color: $color-navy;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-bottom: 20px
  }
}

.footer {
  padding: 20px;

  .bodyFooter {
    font-family: 'Roboto';
    color: $color-navy;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }

  .footerHeaderTable {
    color: $color-black;
    line-height: 2rem;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: auto;
  }

  .footerHeaderTableRow {
    background-color: $color-headTable !important;
  }

  tr:nth-child(odd) {
    background-color: $bg-white;
  }

  tr:nth-child(even) {
    background-color: $bg-tableStripped;
  }

  .btnBottom {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .cancel {
      width: 25%;
      margin-right: 20px;
      background-color: $bg-yellow2;
      color: $color-yellow;
      border: 1px solid $bg-yellow2;

      &:hover {
        background-color: $color-yellow;
        color: $color-white;
        border: 1px solid $color-yellow;
      }
    }
    .submit {
      width: 25%;
    }
  }
}
