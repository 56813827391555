@import './../../../../../styles/variable.scss';
.boxImage {
  display: flex;
  flex-wrap: wrap;

  .boxItemImage {
    margin: 8px;
    width: -webkit-fill-available;
  }
}

.uploadImages {
  position: relative;
  left: 0;
  top: 0;
  width: 145px;
  height: 100px;
  width: -webkit-fill-available;
  .deleteImage {
    position: absolute;
    right: -2px;
    z-index: 5;
    top: -8px;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
    background: $bg-menuNumberInfo;
    border-radius: 50%;
    color: $color-white;
    width: 20px;
    height: 20px;
    line-height: 20px;
    opacity: 0;
  }
  &:hover {
    .deleteImage {
      opacity: 1;
      transition: opacity 0.3s;
      transition-property: opacity;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.51, 0.21, 0.35, 0.64);
      transition-delay: 0s;
    }
  }

  .buttonFile {
    text-align: center;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    width: 6vw;
    height: 6vw;
    overflow: hidden;
    border-radius: 100%;
    position: relative;
    padding: 10px;
    .imgDefault {
      width: 30px;
      height: 30px;
    }
  }
  .image {
    vertical-align: middle;
    border-style: none;
    object-fit: cover;
    width: auto;
    height: 100%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
.sttImage {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: $color-grey1;
  margin-top: 10px;
}

.btnAdd {
  color: $bg-yellow1;
  font-size: 40px;
}
