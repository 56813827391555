@import "styles/variable.scss";

.modalAddDivision {
  .avatarUpload {
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    justify-items: center;

    .input-upload {
      display: none;
    }

    .label-upload {
      width: 95px;
      height: 95px;
      border-radius: 50%;
      cursor: pointer;
      object-fit: cover;
    }
  }

  .titleField {
    color: #222433;
  }

  .textSubField {
    color: #222433;
    font-size: 14px;
  }

  .button-add-department {
    width: 100%;
    &:hover {
      color: white !important;
    }
  }

  .orange {
    background-color: $bg-yellow1 !important;
    border-color: $bg-yellow1 !important;
  }

  .form-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
    user-select: none;
    color: #999;
    pointer-events: none;
    transition: 0.25s ease;
  }
  .form-input:focus,
  .focus-visible:focus:not(:focus-visible) {
    outline: 0;
  }
  .form-input:focus + .form-label {
    top: 0;
    left: 11px;
    padding: 0 10px;
    display: inline-block;
    background-color: $color-white;
    color: $color-green;
  }
  .input-focus-custom {
    top: 0;
    left: 8px;
    padding: 0 6px;
    display: inline-block;
    background-color: $color-white;
    color: $color-green;
    font-size: 12px;
  }
  .form-input {
    border: 1px solid #eee;
    border-radius: 4px;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    transition: 0.25s ease;
  }
  .form-input:focus,
  .form-input:hover {
    border-color: $color-green;
  }
  .form-field {
    position: relative;
    svg {
      width: 25px;
      height: 25px;
      position: absolute;
      right: 10px;
      top: 14px;
      color: #999;
      cursor: pointer;
    }
    &:hover {
      label {
        color: $color-green;
      }
    }
  }
  .form-field {
    position: relative;
    .react-datepicker-popper {
      z-index: 10;
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
      left: -15px;
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
      left: -15px;
    }
  }
}
