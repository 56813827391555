@import './../../../styles/variable.scss';

.department {
  min-width: 1240px;
  .remove-pd-mg {
    padding: 0 0 !important;
    margin: 0 0 !important;
  }

  .border-2 {
    border: 2px solid $color-black;
  }

  // orange zone
  .border-orange {
    border-color: $color-yellow !important;
  }

  .cirle-orange {
    color: $color-yellow;
    background: $color-yellow1;
  }

  // green zone
  .border-green {
    border-color: $color-green !important;
  }

  .cirle-green {
    color: $color-green;
    background: $color-green1;
  }

  .bg-code-green {
    background: $color-green;
  }

  // pink zone
  .border-pink {
    border-color: $color-pink !important;
  }

  .cirle-pink {
    color: $color-pink;
    background: $color-pink1;
  }

  .bg-code-pink {
    background: $color-pink;
  }

  // grey zone
  .border-grey {
    border-color: $color-grey1 !important;
  }

  .cirle-grey {
    color: $color-grey1;
    background: $bg-sidebar;
  }

  .bg-code-grey {
    background: $color-grey1;
  }

  // blue zone
  .border-blue {
    border-color: $color-blue2 !important;
  }

  .cirle-blue {
    color: $color-blue2;
    background: $color-blue3;
  }

  .bg-code-blue {
    background: $color-blue2;
  }

  // more zone

  .cirle-more {
    color: $color-yellow;
    font-size: 200%;
    background: $bg-tooltip;
  }

  .border-dashed {
    border: 1px dashed $color-silver;
  }

  .border-1 {
    border: 1px solid $color-black;
  }

  .diagram {
    transform: translateX(-80px);
    ul {
      padding: 0;
      position: relative;

      li {
        height: 100px;
        list-style-type: none;
        position: relative;
      }
    }
  }

  .none-division {
    display: none;
  }

  //background zone
  .bg-active {
    background: $color-white1;
  }

  .bg-inactive {
    background: $color-table;
  }

  .bg-more {
    background: transparent;
  }

  .team-radius {
    border-radius: 5px;
  }

  .division-radius {
    border-radius: 42.5px;
  }

  .division-box-shadow {
    box-shadow: 0px 5px 25px $color-grey;
  }

  .division-box {
    cursor: pointer;
    right: 10%;
    position: absolute;
    top: 35%;
    margin: 0 auto;
    padding: 1px 1px;
    width: 44%;
    height: 45%;
    line-height: 39.8px;
    vertical-align: middle;
    text-align: center;
    .founding-at {
      position: absolute;
      border-radius: 5px;
      top: -15px;
      margin-top: 5px;
      width: auto;
      height: 16.01px;
      right: 16px;
      color: #f9fbfc;
      padding: 0 12px 0 12px;
      span {
        position: relative;
        top: -10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .number-box {
      display: inline-block;
      width: 41.04px;
      height: 39.04px;
      float: left;
      /* Button */
      border-radius: 50%;
      box-sizing: border-box;
    }

    .setting-btn {
      background: transparent;
      border-color: transparent;
      float: right;
      box-shadow: none !important;

      .change-width {
        width: 204px;
        left: -390% !important;
      }

      .change-padding {
        padding-bottom: 0px;
      }

      .change-margin {
        margin: 0px;
      }

      .change-direction {
        transform: rotate(90deg);
        color: $color-silver;
        box-shadow: none !important;
        background: transparent;
        border-color: transparent;
        padding: 0;
        width: fit-content;
        height: fit-content;
      }

      .icon-diagram {
        font-size: 20px;
        margin-right: 15px;
      }

      .dropdown-item.active,
      .dropdown-item:active {
        text-decoration: transparent;
        background-color: transparent;
      }

      .btn-secondary:not(:disabled):not(.disabled):active {
        background: transparent;
        border-color: transparent;
      }
    }

    .code {
      display: inline-block;
      width: 45.04px;
      height: 16.01px;
      color: $color-white;
      border-radius: 5px;
      line-height: 16.01px;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      right: 11%;
      top: -25%;
      position: absolute;
    }

    .color-active {
      font-weight: 500;
      color: $color-navy;
    }

    .color-inactive {
      color: $color-silver;
    }

    .text-box {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      /* Button text */
      font-family: Roboto;
      font-style: normal;
      width: 52%;
      font-size: 10pt;
      /* or 157% */
      line-height: 1.1;
      align-items: center;
      margin: auto;
    }

    @media only screen and (max-width: 1550px) {
      .text-box {
        font-size: 8pt;
      }
    }
  }

  @media only screen and (max-width: 1550px) {
    .division-box {
      width: 52%;
    }
  }

  .line-orange {
    background: $color-yellow !important;
    height: 2px;
  }

  .line-vertical-orange {
    background: $color-yellow !important;
    width: 3px !important;
  }

  .line-grey-left {
    background-color: $color-grey1;
    width: 50%;
    position: absolute;
    top: 43%;
  }

  .line-grey-right {
    background-color: $color-grey1;
    width: 30%;
    position: absolute;
    top: 43%;
    right: 0%;
  }

  .left-border-inactive {
    border-left: 1px solid $color-grey1;
  }

  .vertical-line {
    height: 100%;
    position: absolute;
    top: 60%;
    width: 1px;
    background: $color-grey1;
  }

  .number-branch {
    position: absolute;
    left: -18px;
    top: 48px;
    z-index: 1;
    background: $color-yellow;
    width: 35.42px;
    height: 21.88px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    padding: 4px;
    color: $color-white;
  }

  @media only screen and (max-width: 1024px) {
    .division-box {
      width: 70%;
      height: 45%;
    }

    .line-grey-left {
      width: 32%;
    }

    .number-branch {
      left: -6%;
      margin-top: 19.5%;
    }
  }
}

.disableBranch {
  width: 100%;
  height: 100%;
  padding-left: 8px;
}

.department-tooltip {
  .tooltip-inner {
    background-color: $bg-plum;
    color: $color-charcoal;

    .division-head {
      color: $color-navy;
      font-weight: 500;
    }

    p {
      text-align: left;
      margin-bottom: 0;
      font-size: 10pt;

      span {
        color: $color-black1;
        font-weight: 500;
      }
    }
  }

  .tooltip .arrow {
    display: none;
  }
}

.editDeleteButton {
  padding: 3px;
  border: 1px dashed $color-borderTable;
  border-radius: 100%;
  height: 34px;
  width: 33px;
  vertical-align: middle;
  margin-right: 0;
  margin-top: 0px;
  cursor: pointer;

  svg {
    color: $color-menuActive;
    font-size: 24px;
  }
}

.editDeleteButton:hover {
  transform: scale(1.1);
}

.textButton {
  margin-top: 7px;
  margin-left: 10px;
  color: $color-nav;
}

.line-team-division-department {
  width: 100%;
  height: 1px;
  background: $color-borderTable;
}

.manager-block-team-division {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: 5px 0;
  cursor: pointer;

  .textSubField {
    margin-bottom: 0;
    font-size: 14px;
  }

  .block-noicons {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .add-manager {
      overflow: hidden;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 1px dashed $color-borderTable;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $bg-yellow1;
      cursor: pointer;
    }

    .content-add {
      width: 123px;
      height: 22px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $bg-nav;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 10px;
    }
  }

  .block-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .each-icon {
      width: 25px;
      height: 25px;
      background: $bg-body;
      border: 1px solid $color-borderTable;
      box-sizing: border-box;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-menuActive;
      cursor: pointer;

      .icon-spinner-modal-team {
        font-size: 14px;
      }
    }
  }
}

.choosen-manager {
  background: $color-exportActive;
}
