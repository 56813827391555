@import "styles/variable.scss";

.depreciation-assets-table {
  .scroll-custom {
    .asset-header {
      th {
        border-bottom: 0;
        white-space: nowrap;
        background-color: $bg-salmon !important;
      }
    }
    .head-title {
      th {
        background-color: $bg-salmon !important;
        border-bottom: none;
      }
    }

    .sum-header {
      position: sticky;
      top: 88px;
      th {
        background-color: $bg-salmon !important;
      }
    }
    .text-new-line {
      text-overflow: ellipsis;
      overflow: hidden; 
    }
  }

  // Scroll style

  .sum {
    background-color: $bg-salmon !important;
    font-weight: bold !important;
  }

  @media only screen and (max-width: 1650px) {
    .table-scroll {
      width: 150% !important;
    }
  }
}
