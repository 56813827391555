@import 'styles/variable.scss';

.filterDate {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: $color-navy;
    margin-bottom: 0;
  }
  div {
    height: 37px;
  }
}

.boxStatisticTimeLastOT {
  .info {
    height: 615px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
    @media (max-width: 1440px) {
      height: 615px;
    }
    @media (max-width: 1024px) {
      height: 598px;
    }
  }

  .bgTimeLate,
  .bgTimeLateUnApprove,
  .bgTimeOT {
    font-size: 12px;
    color: $color-silver;
    p {
      display: inline-block;
      width: 23px;
      height: 10px;
      margin-bottom: 0;
      margin-right: 2px;
    }
  }
  .bgTimeLate {
    margin-right: 20px;
    p {
      background-color: $bgTimeLate;
    }
  }
  .bgTimeLateUnApprove {
    margin-right: 20px;
    p {
      background-color: $bgTimeLateUnApprove;
    }
  }
  .bgTimeOT {
    p {
      background-color: $bgTimeOT;
    }
  }

  .loadingLateOTMonth {
    position: relative;
    top: -50%;
    left: 50%;
  }
}

.boxRatingTimeOt {
  :global {
    .title-body {
      height: 30px;
    }
  }
  @media (max-width: 1440px) {
    :global {
      .title-body {
        margin-bottom: 15px;
      }
    }
  }

  .ratingTimeOt {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 0;
    height: 662px;
    overflow: auto;
    @media (min-width:1024px) and (max-width:1256px) {
      height: 690px;
    }
    @media (max-width: 1024px) {
      height: 598px;
    }

    li {
      padding: 9.5px 15px;
      &:nth-child(even) {
        background-color: $bg-row-even;
      }
      .project {
        font-size: 14px;
        color: $color-charcoal;
        display: inline-block;
        margin-bottom: 0;
      }
      .time {
        font-weight: 500;
        font-size: 14px;
        color: $color-blueGray;
        display: inline-block;
        margin-bottom: 0;
      }
    }
  }
}

.boxList {
  padding: 20px 21px;

  .listInfo {
    margin-bottom: 0;

    li {
      .text,
      .number {
        margin-bottom: 0;
      }

      .text {
        font-size: 14px;
        margin-bottom: 15px;
        color: $color-silver;
        @media (max-width: 1024px) {
          margin-bottom: 10.5px;
        }
      }
      .number {
        font-size: 16px;
        font-weight: 500;
        color: $color-navy;
      }
      &:last-child {
        .text {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.boxChartDoughnut {
  canvas {
    position: relative;
    z-index: 100;
  }
}

.boxChartNoteDoughnut {
  .note {
    height: 510px;
    overflow: auto;
    margin: 0px -15px;

    @media (max-width: 1024px) {
      height: 495px;
    }

    li {
      padding: 10px 15px;

      &:nth-child(even) {
        background-color: $bg-row-even;
      }
    }

    .text,
    .time,
    .money {
      font-size: 14px;
    }
    .text {
      width: 33.3333333%;
      color: $color-charcoal;
      span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }
    }
    .time {
      width: 33.3333333%;
      font-weight: 500;
      color: $color-blueGray;
      text-align: center;
    }
    .money {
      width: 33.3333333%;
      font-weight: 500;
      color: $color-navy;
      text-align: right;
    }
  }
}

.chartDoughnut {
  position: relative;
  top: 0;
  left: 0;
  width: 180px;
  margin: 0px auto;

  .infoDoughnut {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);

    .info {
      text-align: center;
      .text {
        color: $color-silver;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }
      .total {
        color: $color-charcoal;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0px;
      }
    }
  }
}
.hoursTitle {
  padding: 0 20px;
  margin-bottom: 20px;
  color: #223354;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.divisionTitle {
  color: #223354;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
}
