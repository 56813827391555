@import './../../../styles/variable.scss';

a:hover {
  cursor: pointer;
}

.container-fluid {
  padding: 0;
  background-color: $bg-body;
  width: auto;
}

.navTab {
  border-bottom: none;
}

.navLink {
  line-height: 19px;
  padding: 10px 25px;
  font-size: 18px;
  text-transform: uppercase;
}

.iconClose {
  width: 26px;
  height: 25px;
  box-sizing: border-box;
  border-radius: 50%;
  font-size: 19px;
  padding-left: 5px;
  color: #fea628;
  position: absolute;
  top: 11px;
  right: 65px;
  background-color: #efeded;

  svg {
    margin-top: -6px;
    margin-left: -1px;
  }

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}

.navLink:hover {
  border: none;
}

.active.navLink {
  border: none;
  border-bottom: 2px solid $color-menuActive;
  color: $color-navActive;
  font-style: normal;
  font-weight: bold;
}

.containerRoot {
  margin: auto;
  width: 100%;
  background: $bg-body;
  overflow-x: hidden;
  z-index: -1;

  .link {
    display: flex;
    margin-left: 15px;
    margin-bottom: 10px;
  }

  .rowInfo {
    margin: 0;

    .contentRoot {
      width: 100%;
      margin: 20px 0;
      position: relative;
      padding: 0;
    }

    .navbar-search {
      .input-group {
        .input-group-text {
          color: $color-black;
        }
      }
    }

    .nav-item {
      cursor: pointer;
    }

    .nav.nav-tabs {
      padding-left: 15px;

      a.nav-link {
        color: $color-navActive;
        font-style: normal;
        font-weight: 500;
      }

      border: none;

      a {
        font-weight: 500;
      }
    }

    .major {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 140px;
    }

    .widthCustom {
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      font-weight: revert;
      text-overflow: ellipsis;
    }

    .editDeleteButton {
      padding: 3px;
      background-color: $color-button;
      border: 1px solid $color-borderTable;
      border-radius: 100%;
      height: 34px;
      width: 33px;
      vertical-align: middle;
      float: right;
      margin-right: 0;
      margin-top: 0px;

      svg {
        color: $color-menuActive;
        font-size: 24px;
      }
    }

    .editDeleteButton:hover {
      transform: scale(1.2);
      cursor: pointer;
    }

    .nextButton {
      z-index: 1;
      width: 25px;
      height: 26px;
      border: 1px solid $color-borderTable;
      border-radius: 100%;
      background-color: $bg-body;
      position: absolute;
      right: 10px;
      top: 48%;
    }

    .nextButton:hover {
      transform: scale(1.2);
      cursor: pointer;
    }

    .prevButton {
      z-index: 1;
      width: 25px;
      height: 26px;
      border: 1px solid $color-borderTable;
      border-radius: 100%;
      background-color: $bg-body;
      position: absolute;
      right: 10px;
      top: 48%;
    }

    .prevButton:hover {
      transform: scale(1.2);
      cursor: pointer;
    }

    .Content-root {
      padding-left: 9%;
      padding-right: 9%;
      margin-top: 55px;
    }

    .imgTitle {
      margin-bottom: 7px;
      margin-right: 7px;
      width: auto;
      height: 21px;
      margin-top: 3px;
    }

    .profileBox {
      width: 100%;
      height: auto;
      border: 1px solid $color-borderTable;
      border-radius: 5px;
      color: $color-black;
      padding: 1rem 0;
      margin-bottom: 20px;
      background-color: white;
      box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

      .rowTitle {
        margin: auto;
        border-bottom: 1px solid $color-grey;
        width: 96%;

        .profileTitle {
          img {
            padding-bottom: 3px;
            margin-right: 7px;
            height: 24px;
            width: auto;
          }
        }
      }
    }

    .profileTitle {
      padding: 0 1rem 0.4rem 0;
      font-size: 20px;
      line-height: 40px;
      color: $color-navActive;
      font-weight: 500;
      margin: auto;
      width: 100%;
      margin-left: -15px;
    }

    .profileTitleChild {
      margin: 15px 50px;
      font-size: 1rem;
      color: $color-navActive;
      font-weight: 500;
    }

    .salaryBoxParent {
      margin: 0 2rem;
      position: relative;

      th {
        text-transform: none;
        border: none;

        &:first-child {
          border-top-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px;
        }
      }

      :global {
        .table-list {
          border: 1px solid $color-white;
          border-radius: 5px;
        }
      }
    }

    .salaryBox {
      width: 25%;
      height: auto;
      margin-top: 20px;
      margin-right: 3%;
      padding: 12px 15px 5px 15px;
      color: $color-navActive;
      border-radius: 5px;
      border: 1px solid $color-borderTable;
      background-color: $color-childTable;

      p {
        line-height: 16px;
      }
    }

    .salaryBoxContent {
      font-size: 14px;
      line-height: 16px;
      padding: 10px 0;
      font-weight: bold;
    }

    .profileContentInfo {
      color: $color-navActive;
      line-height: 2rem;
      font-size: 0.9rem;
    }

    .profileContentInfoAlert {
      color: $color-navActive;
      line-height: 2rem;
      font-size: 1.1rem;
      padding: 40px;
      text-align: center;
      color: $bg-menuNumberInfo;
    }

    .profileContentField {
      color: $color-navActive;
      font-weight: bold;
      line-height: 2rem;
      font-size: 0.9rem;
    }

    .assetHeader {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;
    }

    .assetContent {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;
    }

    .profileContent {
      width: 94%;
      margin: auto;
    }

    .editDeleteButtonChild:hover {
      cursor: pointer;
      transform: scale(1.2);
    }

    //Infomation box
    .profileBoxInfo {
      padding-bottom: 0;
      width: 100%;
      height: auto;
      border: 1px solid $color-borderTable;
      border-radius: 5px;
      color: $color-black;
      padding: 1rem 0 0 0;
      margin-bottom: 20px;
      background-color: white;
      box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

      .rowTitle {
        margin: auto;
        border-bottom: 1px solid $color-grey;
        width: 96%;

        .profileTitle {
          img {
            padding-bottom: 3px;
            margin-right: 7px;
            height: 24px;
            width: auto;
          }
        }
      }

      .profileContent {
        width: 100%;
        margin: auto;
        padding-bottom: 0;

        .profileContentInfo {
          vertical-align: middle;
          display: flex;
          padding: 10px;

          div {
            width: 20%;
            color: $color-charcoal;
          }

          div:first-child {
            padding-left: 25px;
          }

          div:nth-child(2) {
            width: 40%;
            padding-right: 15px;
          }
        }

        .profileContentInfo div:first-child,
        :nth-child(3) {
          font-weight: 500;
        }

        .profileContentInfo div:nth-child(2),
        :last-child {
          font-weight: 400;
        }

        .profileContentInfo:nth-child(2) {
          background: $color-tableStripped;
        }

        .profileContentInfo:nth-child(4) {
          background: $color-tableStripped;
        }

        .profileContentInfo:last-child {
          background: $color-tableStripped;
        }
      }
    }

    .profileBoxContract {
      overflow: auto;
      overflow-x: hidden;
      width: 100%;
      height: auto;
      border: 1px solid $color-borderTable;
      border-radius: 5px;
      color: $color-black;
      padding: 1rem 0;
      margin-bottom: 20px;
      background-color: white;
      box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

      .rowTitle {
        margin: auto;
        border-bottom: 1px solid $color-grey;
        width: 96%;

        .profileTitle {
          img {
            padding-bottom: 3px;
            margin-right: 7px;
            height: 24px;
            width: auto;
          }
        }
      }

      .profileTitle {
        img {
          padding-bottom: 3px !important;
        }
      }

      .profileTitle {
        margin: auto;
        width: 96%;
      }

      .borderDashed {
        margin: 20px 40px 0 40px;
        border-bottom: 1px dashed $color-truncate;
      }

      .salaryBoxParent {
        overflow: unset !important;
        position: relative;

        .salaryBox {
          position: relative;

          div {
            line-height: 1.5rem;
            padding-bottom: 5px;
            color: $color-charcoal;
          }

          .point {
            width: 15px;
            height: 15px;
            position: absolute;
            top: -29px;
            left: 47%;
            background: $color-navActive;
            border-radius: 15px;
          }

          .contentBox {
            display: flex;
            justify-content: space-between;

            .profileContentInfo {
              font-weight: 400 !important;

              img {
                padding-bottom: 3px;
                margin-right: 5px;
                width: 10px;
                height: 13px;
              }
            }
          }

          .profileContentInfo {
            font-weight: 500;
            font-size: 14px;
          }

          width: 28%;
        }

        .salaryBoxNow {
          width: 25%;
          height: auto;
          margin-top: 20px;
          margin-right: 3%;
          padding: 12px 15px 5px 15px;
          border-radius: 5px;
          border: 1px solid $color-borderTable;
          background: $color-boxCareerActive;
          position: relative;

          p {
            line-height: 16px;
          }

          div {
            line-height: 1.5rem;
            padding-bottom: 5px;
            color: $color-charcoal;
          }

          .point {
            background: $color-green;
            width: 15px;
            height: 15px;
            position: absolute;
            top: -28px;
            left: 47%;
            border-radius: 15px;
          }

          .contentBox {
            display: flex;
            justify-content: space-between;

            .profileContentInfo {
              font-weight: 400 !important;

              img {
                padding-bottom: 3px;
                margin-right: 5px;
                width: 10px;
                height: 13px;
              }
            }
          }

          .profileContentInfo {
            font-weight: 500;
          }

          width: 28%;
        }

        .nextButton {
          z-index: 1;
          position: absolute;
          top: 53px;
          right: -28px;

          img {
            width: 21px;
            height: 26px;
            padding: 7px;
          }
        }

        .prevButton {
          z-index: 1;
          position: absolute;
          top: 53px;
          left: -29px;

          img {
            width: 21px;
            height: 26px;
            padding: 7px;
          }
        }
      }

      .imgTitle {
        margin-left: 1px;
      }
    }

    .profileBoxEdu {
      overflow: auto;
      overflow-x: hidden;
      width: 100%;
      height: auto;
      border: 1px solid $color-borderTable;
      border-radius: 5px;
      color: $color-black;
      padding: 1rem 0;
      margin-bottom: 20px;
      background-color: white;
      box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

      .achievementNull {
        margin: auto;
        margin-top: 3rem;

        .profileContentInfoAlert {
          clear: both;
          color: $color-truncate;
          text-align: center;
          padding: 20px;
        }

        .editDeleteButton {
          width: 40px;
          height: 41px;
          border: 1.5px dashed $color-borderTable;
          margin-right: 48.5%;

          svg {
            margin: 3.5px;
          }
        }
      }

      .rowTitle {
        margin: auto;
        border-bottom: 1px solid $color-grey;
        width: 96%;

        .profileTitle {
          img {
            padding-bottom: 3px;
            margin-right: 7px;
            height: 24px;
            width: auto;
          }
        }
      }

      .imgTitle {
        margin-left: 1px;
      }

      .editDeleteButtonChild {
        padding: 3px;
        color: $color-truncate;
        border-radius: 24px;
        border: 1px solid $color-borderTable;
        background-color: $bg-body;
        float: right;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        margin-left: 10px;

        svg {
          font-size: 18px;
        }
      }

      .educationBox {
        box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
        height: 140px;
        width: 96.5%;
        margin-top: 20px;
        margin-right: 3%;
        padding: 12px 12px 5px 15px;
        color: $color-navActive;
        border-radius: 5px;
        position: relative;

        div:first-child {
          display: flex;
          font-weight: 500;
        }

        .educationDetail {
          position: absolute;
          bottom: 5px;
        }

        .educationHeaderTitle {
          padding-bottom: 10px;
        }

        .educationBoxTitle {
          margin-top: 5px;
          width: 75%;
          p {
            font-size: 1rem;
            line-height: 1.5rem;
            white-space: nowrap;
            overflow: hidden;
            font-weight: revert;
            text-overflow: ellipsis;
          }
        }

        .educationEdit {
          width: 25%;
          display: flex;
        }
      }

      .salaryBoxParent {
        overflow: unset !important;
        display: flex;

        .educationDetail {
          .profileContentInfo:first-child {
            padding: 0;

            img {
              padding-bottom: 3px;
              margin-right: 5px;
              width: 21px;
              height: 19px;
              margin-top: 6px;
              margin-right: 7px;
            }
          }

          .profileContentInfo:last-child {
            padding: 0;

            img {
              padding-bottom: 3px;
              margin-right: 5px;
              width: 21px;
              height: 24;
            }
          }
        }

        .nextButton {
          z-index: 1;
          position: absolute;
          top: 70px;
          right: -28px;

          img {
            width: 21px;
            height: 26px;
            padding: 7px;
          }
        }

        .prevButton {
          z-index: 1;
          position: absolute;
          top: 70px;
          left: -30px;

          img {
            width: 21px;
            height: 26px;
            padding: 7px;
          }
        }
      }
    }

    .profileBoxSkill {
      overflow: auto;
      overflow-x: hidden;
      font-weight: normal;
      width: 100%;
      height: auto;
      border: 1px solid $color-borderTable;
      border-radius: 5px;
      color: $color-black;
      padding: 1rem 0;
      margin-bottom: 20px;
      background-color: white;
      box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

      .salaryBoxParent {
        overflow: unset !important;
        display: flex;
      }

      .achievementNull {
        margin: auto;
        margin-top: 3rem;

        .profileContentInfoAlert {
          clear: both;
          color: $color-truncate;
          padding: 20px;
        }

        .editDeleteButton {
          width: 40px;
          height: 41px;
          border: 1.5px dashed $color-borderTable;
          margin-right: 32%;

          svg {
            margin: 3.5px;
          }
        }
      }

      .rowTitle {
        margin: auto;
        border-bottom: 1px solid $color-grey;
        width: 96%;

        .profileTitle {
          img {
            padding-bottom: 3px;
            margin-right: 7px;
            height: 24px;
            width: auto;
          }
        }
      }

      .contentSkill {
        display: flex;
        margin: 0 2rem;
      }

      .prevButton {
        left: -30px;

        img {
          width: 21px;
          height: 26px;
          padding: 7px;
        }
      }

      .nextButton {
        right: -30px;

        img {
          width: 21px;
          height: 26px;
          padding: 7px;
        }
      }

      .labelSkill {
        color: $color-navActive;
        margin-top: 20px;
        margin-right: 10px;
        line-height: 32px;
        background-color: $color-yellow1;
        padding: 0 12px;
        border-radius: 32px;
        cursor: default;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 15px;
          height: 16px;
        }

        img:hover {
          transform: scale(1.1);
          cursor: pointer;
        }
      }

      .imgTitle {
        margin-left: 4px;
      }
    }

    .profileBoxCV {
      width: 100%;
      height: auto;
      border: 1px solid $color-borderTable;
      border-radius: 5px;
      color: $color-black;
      padding: 1rem 0;
      margin-bottom: 20px;
      background-color: white;
      box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

      .fixParent {
        position: relative;
        display: flex;

        .pdfThumb {
          margin-right: 1px !important;
        }

        .fix {
          position: absolute;
          width: 16px;
          background-color: white;
          height: 110px;
          right: 0;
          top: -5px;
        }
        .fixBottom {
          position: absolute;
          width: 105%;
          background-color: white;
          height: 15px;
          right: 0;
          left: -1px;
          bottom: -1px;
        }
      }

      .buttonFile {
        cursor: pointer;
      }

      .achievementNull {
        margin: auto;
        width: 118px;
        margin-top: 3rem;

        .profileContentInfoAlert {
          clear: both;
          color: $color-truncate;
          padding: 20px;
          text-align: center;
        }

        .editDeleteButton {
          width: 40px;
          height: 41px;
          border: 1.5px dashed $color-borderTable;
          margin-right: 32%;

          svg {
            margin: 3.5px;
          }
        }
      }

      .rowTitle {
        margin: auto;
        border-bottom: 1px solid $color-grey;
        width: 96%;

        .profileTitle {
          img {
            padding-bottom: 3px;
            margin-right: 7px;
            height: 24px;
            width: auto;
          }
        }
      }

      .contentCV {
        margin: 30px 30px 0 -10px;

        .displayContentCV {
          display: flex;

          embed {
            border-radius: 5px;
            margin-right: 10px;
            cursor: pointer;
          }

          .profileContentInfo {
            line-height: 28px;
          }

          div.profileContentInfo:first-child {
            font-size: 1rem;
            font-weight: 700;
          }

          div.profileContentInfo:not(:first-child) {
            color: $color-dataCV;
            font-weight: 400px;
          }

          img:first-child {
            border-radius: 6px;
            width: 82px;
            height: 82px;
            margin-right: 15px;
          }

          .editDeleteCV:first-child {
            img {
              width: 22px;
              height: 26px;
            }
          }

          .editDeleteCV:nth-child(2) {
            margin-right: 20px;

            img {
              width: 30px;
              height: 26px;
            }
          }

          .editDeleteCV:hover {
            transform: scale(1.2);
            cursor: pointer;
          }

          .displayButtonCV {
            display: flex;
          }

          input {
            display: none;
          }
        }
      }

      .imgTitle {
        margin-left: 10px;
      }
    }

    .profileBoxAchie {
      width: 100%;
      height: auto;
      border: 1px solid $color-borderTable;
      border-radius: 5px;
      color: $color-black;
      padding: 1rem 0;
      margin-bottom: 20px;
      background-color: white;
      box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

      .rowTitle {
        margin: auto;
        border-bottom: 1px solid $color-grey;
        width: 96%;

        .profileTitle {
          img {
            padding-bottom: 3px;
            margin-right: 7px;
            height: 24px;
            width: auto;
          }
        }
      }

      .salaryBoxParent {
        margin: 0 2em;

        .certificationBox {
          padding: 11px;
          background: $color-table;
          box-sizing: border-box;
          box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
          border-radius: 5px;
          width: 100%;
          display: flex;
          margin: 5px 0;
          align-items: center;

          .leftCerti {
            width: 65px;
            height: 65px;
            cursor: pointer;
          }

          .time {
            font-size: 12px;
            line-height: 20px;
          }

          img {
            width: 100%;
            border-radius: 5px;
            height: 100%;
            float: left;
          }

          .rightCerti {
            height: 100%;

            .editDeleteButtonChild {
              padding: 3px 3px;
              color: $color-truncate;
              border-radius: 24px;
              float: left;
              margin-left: 20px;
              height: 25px;
              width: 25px;
              border-radius: 50%;
              margin-bottom: 5px;

              svg {
                font-size: 18px;
              }
            }

            div.editDeleteButtonChild:last-child {
              margin-left: 5px !important;
            }

            .profileContentInfo {
              float: left;
              color: $color-truncate;
              padding-left: 25px;
              clear: both;
            }

            .profileContentInfo:first-child {
              font-weight: 500;
              font-size: 16px;
              color: $color-charcoal;
            }
          }
        }

        .achievementNull {
          margin: auto;
          margin-top: 3rem;

          .profileContentInfoAlert {
            clear: both;
            color: $color-truncate;
            padding: 20px;
          }

          .editDeleteButton {
            width: 40px;
            height: 41px;
            border: 1.5px dashed $color-borderTable;
            margin-right: 43.1%;

            svg {
              margin: 3.5px;
            }
          }
        }
      }

      .imgTitle {
        margin-left: 11px;
      }
    }

    .profileBoxBaby {
      width: 100%;
      height: auto;
      border: 1px solid $color-borderTable;
      border-radius: 5px;
      color: $color-black;
      padding: 1rem 0;
      margin-bottom: 20px;
      background-color: white;
      box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

      .achievementNull {
        margin: auto;
        margin-top: 3rem;

        .profileContentInfoAlert {
          clear: both;
          color: $color-truncate;
          padding: 20px;
          text-align: center;
        }

        .editDeleteButton {
          width: 40px;
          height: 41px;
          border: 1.5px dashed $color-borderTable;
          margin-right: 48.1%;

          svg {
            margin: 3.5px;
          }
        }
      }

      .rowTitle {
        margin: auto;
        border-bottom: 1px solid $color-grey;
        width: 96%;

        .profileTitle {
          img {
            padding-bottom: 3px;
            margin-right: 7px;
            height: 24px;
            width: auto;
          }
        }
      }

      .profileTitle {
        img {
          padding-bottom: 0px !important;
        }
      }

      thead {
        background-color: $color-headTable;

        .profileContentInfo {
          color: $color-titleBody;
          font-weight: 500;
        }
      }

      tbody {
        .profileContentInfo {
          color: $color-charcoal;
        }
      }

      .salaryBoxParent {
        margin: 21px 24px;
      }

      .editDeleteButtonChild {
        padding: 0px 3px;
        color: $color-truncate;
        border-radius: 24px;
        float: right;
        margin-right: 0;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        margin-bottom: 5px;
        margin-left: 10px;

        svg {
          font-size: 18px;
        }
      }

      .imgTitle {
        margin-left: 13px;
      }
    }

    .profileBoxDoc {
      width: 100%;
      height: auto;
      border: 1px solid $color-borderTable;
      border-radius: 5px;
      color: $color-black;
      padding: 1rem 0;
      margin-bottom: 20px;
      background-color: white;
      box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);

      .rowTitle {
        margin: auto;
        border-bottom: 1px solid $color-grey;
        width: 96%;

        .profileTitle {
          img {
            padding-bottom: 3px;
            margin-right: 7px;
            height: 24px;
            width: auto;
          }
        }
      }

      .rowTitle {
        padding-bottom: 15px;

        .profileTitle {
          margin-top: 10px;

          img {
            padding-bottom: 1px !important;
          }
        }

        .buttonCmt {
          display: flex;
          justify-content: flex-end;
          height: 40px;
          margin-top: 5px;

          .editDeleteButton {
            margin-top: 5px;
            margin-left: 10px;
          }
        }
      }

      .profileContent {
        font-weight: 500;

        .docText {
          font-weight: 400 !important;
        }

        .cmtContent {
          display: flex;
          justify-content: space-between;
        }

        .profileTitleChild {
          margin: 5px 10px;
        }

        .profileContentInfo {
          padding: 10px;
        }

        .boxImages {
          width: 100%;
          height: 150px;
          padding-top: 15px;

          img {
            width: 100%;
            height: 100%;
            border: 1px solid $color-borderTable;
            border-radius: 10px;
            object-fit: cover;
          }

          div {
            font-size: 14px;
            line-height: 22px;
          }
        }

        .editDeleteButtonChild {
          padding: 0px 3px;
          color: $color-truncate;
          border-radius: 24px;
          border: 1px solid $color-borderTable;
          background-color: $bg-body;
          float: right;
          height: 25px;
          width: 25px;
          border-radius: 50%;
          margin-top: 10px;
          margin-left: 10px;

          svg {
            font-size: 18px;
          }
        }
      }
    }

    .imgTitle {
      margin-left: 15px;
    }
  }

  .profileContent tr td {
    font-size: 14px;
    line-height: 40px;
    color: $color-navActive;
    padding: 5px 20px;
    max-width: 25%;
    word-wrap: break-word;
  }

  .contentRoot_2 {
    width: 100%;
    margin: 20px 0;
    position: relative;
    padding: 0;

    .row {
      margin: 0;
    }

    .assetMain {
      margin-top: 20px;

      .assetHeader tr {
        background-color: $color-headTable !important;
      }

      tr:nth-child(odd) {
        background-color: $bg-white;
      }

      tr:nth-child(even) {
        background-color: $bg-tableStripped;
      }

      .nameAssets {
        font-weight: 500;
      }

      :global {
        .MuiTableHead-root tr {
          background-color: $color-headTable !important;
        }
        .MuiTable-root {
          .MuiTableCell-root {
            color: $color-titleBody;
          }
        }
      }
    }

    .assetTopLeft {
      border-radius: 5px;
      width: 100%;
      height: 90px;
      display: flex;
      position: relative;

      img.imgAssetHeaderLeft {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        margin: 0;
      }

      div {
        margin-left: 10px;
        margin-top: 33px;
        color: white;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
      }

      .elementLeft {
        position: absolute;
        left: 10%;
      }
      .elementRight {
        position: absolute;
        font-size: 40px;
        text-align: right;
        right: 5%;
      }
      @media screen and (max-width: 900px) {
        .elementLeft {
          left: 2%;
          top: 30%;
        }
        .elementRight {
          top: 0%;
        }
      }

      .iconAssetHeaderLeft {
        position: absolute;
        left: 4%;
        top: 29px;
        width: 30px;
        height: 30px;
        border-radius: 3px;
      }
    }

    .assetTopRight {
      border-radius: 5px;
      width: 100%;
      height: 90px;
      display: flex;
      position: relative;

      img.imgAssetHeaderRight {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        margin: 0;
      }

      div {
        color: white;
        font-size: 20px;
        line-height: 24px;
        margin-left: 10px;
        font-weight: 500;
      }

      .elementLeft {
        position: absolute;
        left: 10%;
        margin-top: 33px;
      }
      @media screen and (max-width: 1300px) {
        .elementLeft {
          left: 2%;
          top: 30%;
        }
        .elementRight {
          top: 30%;
        }
      }
      @media screen and (max-width: 1050px) {
        .elementLeft {
          left: 2%;
          top: 30%;
        }
        .elementRight {
          top: 0%;
        }
      }
      .elementRight {
        position: absolute;
        font-size: 20px;
        text-align: right;
        right: 5%;
        margin-top: 33px;
      }

      .iconAssetHeaderRight {
        position: absolute;
        left: 4%;
        top: 29px;
        width: 35px;
        height: 30px;
        border-radius: 3px;
      }
    }

    thead {
      background-color: $color-headTable;
    }

    .contentRoot {
      padding: 0 0;
    }

    .profileBox {
      padding: 0;

      table {
        width: 100%;
        margin: 0;
        table-layout: fixed;

        .profileContentInfo {
          padding-left: 20px;
          word-wrap: break-word;
        }

        thead {
          background-color: $color-headTable;

          .profileContentInfo {
            color: $color-titleBody;
          }
        }

        tr {
          font-weight: 500;
          color: $color-charcoal;
        }
      }
    }
  }

  .contentRoot_3 {
    width: 100%;
    margin: 20px 0;
    position: relative;
    padding: 0;

    .row {
      margin: 0;
    }

    .assetMain {
      margin-top: 20px;
    }

    .assetTopLeft {
      border-radius: 5px;
      width: 100%;
      height: 90px;
      display: flex;
      position: relative;

      img.imgAssetHeaderLeft {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        margin: 0;
      }

      div {
        margin-left: 10px;
        margin-top: 33px;
        color: white;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
      }

      .elementLeft {
        position: absolute;
        left: 10%;
      }
      @media screen and (max-width: 1300px) {
        .elementLeft {
          left: 10%;
          top: 30%;
        }
      }
      .elementRight {
        position: absolute;
        font-size: 40px;
        text-align: right;
        right: 5%;
      }

      .iconAssetHeaderLeft {
        position: absolute;
        left: 4%;
        top: 29px;
        width: 30px;
        height: 30px;
        border-radius: 3px;
      }
    }

    .assetTopRight {
      border-radius: 5px;
      width: 100%;
      height: 90px;
      display: flex;
      position: relative;

      img.imgAssetHeaderRight {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        margin: 0;
      }

      div {
        color: white;
        font-size: 20px;
        line-height: 24px;
        margin-left: 10px;
        font-weight: 500;
      }

      .elementLeft {
        position: absolute;
        left: 10%;
        margin-top: 33px;
      }
      @media screen and (max-width: 1300px) {
        .elementLeft {
          left: 5% !important;
          top: 30% !important;
        }
      }

      .elementRight {
        position: absolute;
        font-size: 40px;
        text-align: right;
        right: 5%;
        margin-top: 33px;
      }

      .iconAssetHeaderRight {
        position: absolute;
        left: 4%;
        top: 29px;
        width: 35px;
        height: 30px;
        border-radius: 3px;
      }
    }

    .contentRoot {
      padding: 0 0;
    }

    .profileBox {
      padding: 0;

      table {
        color: $color-black;
        background: $color-table;
        border: 1px solid $color-borderTable;
        box-sizing: border-box;
        border-radius: 5px;

        :global {
          .thead-light th {
            background-color: $color-headTable !important;
            color: $color-navActive;
            font-weight: 600;
            font-size: 14px;
            text-transform: none;
          }

          .table-responsive {
            border: 1px solid $color-wheat;
            box-sizing: border-box;
            border-radius: 5px;
          }
        }

        tr:nth-child(odd) {
          background-color: $bg-white;
        }

        tr:nth-child(even) {
          background-color: $bg-tableStripped;
        }

        th.title {
          font-size: 14px;
          font-weight: 600;
        }

        td {
          color: $color-navActive;
        }
      }
    }
  }
}

.paging {
  padding: 10px 0;

  .arrowCount {
    margin-top: 10px;
    padding-left: 35px;

    p {
      font-weight: 400;
      color: $color-truncate;
    }
  }

  .arrowPage {
    text-align: right !important;
    height: 50px;
    padding-right: 50px;

    .dropPerPage {
      position: absolute;
      right: 180px;

      .perpage {
        cursor: pointer;
        border: none;
        color: $color-perPage;
        width: 160px;
        appearance: none;
        background-image: url('./../../../assets/img/icons/icon-down.png');
        background-repeat: no-repeat;
        background-position-x: 96%;
        background-position-y: 16px;
        background-size: 13px 8px;

        &:focus {
          background: $bg-body !important;
          color: $color-perPage !important;
          appearance: none !important;
          background-image: url('./../../../assets/img/icons/icon-down.png') !important;
          background-repeat: no-repeat !important;
          background-position-x: 96% !important;
          background-position-y: 16px !important;
          background-size: 13px 8px !important;
        }
      }
    }

    .arrowLeft {
      margin: 12px 80px 0 40px;
    }

    .arrowRight {
      margin-top: 12px;
    }

    img {
      width: 10px;
      cursor: pointer;
    }
  }
}

.noteImage {
  font-size: 14px;
  color: $color-silver;
}

img.radio {
  width: 20px;
  margin: 0 12px 0 0;
}

img.radio.right {
  margin: 0 12px 0 25px;
}

.gender {
  width: -webkit-fill-available;
}

.cusor {
  cursor: pointer;
}

.modalExportCCCD {
  p {
    margin-bottom: 0px;
  }

  hr {
    margin: 10px 0px;
    background-color: $color-wheat;
  }

  .rowItem {
    .titleExport {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $color-navy;
    }

    .body {
      color: $color-charcoal;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .boxImages {
    height: 150px;
    margin-bottom: 10px;
    overflow: hidden;
  }
}

.inforTotal {
  color: $color-nav;
  font-size: 14px;
}

.loading {
  margin-left: 35%;
  color: $color-red;
}

.arrowPage {
  text-align: right !important;

  .dropPerPage {
    position: absolute;
    right: 150px;

    .perpage {
      cursor: pointer;
      border: none;
      color: $color-nav;
      width: 62px;
      appearance: none;
      background-image: url('./../../../assets/img/icons/icon-down.png');
      background-repeat: no-repeat;
      background-position-x: 96% !important;
      background-position-y: 14px !important;
      background-size: 13px 8px;

      &:focus {
        color: $color-perPage !important;
        appearance: none !important;
        background-image: url('./../../../assets/img/icons/icon-down.png') !important;
        background-repeat: no-repeat !important;
        background-position-x: 96% !important;
        background-position-y: 14px !important;
        background-size: 13px 8px !important;
      }
    }

    .custom {
      background-color: $bg-tableStripped;

      &:focus {
        background: $bg-tableStripped !important;
        color: $color-perPage !important;
        appearance: none !important;
        background-image: url('./../../../assets/img/icons/icon-down.png') !important;
        background-repeat: no-repeat !important;
        background-position-x: 96% !important;
        background-position-y: 18px !important;
        background-size: 13px 8px !important;
      }
    }
  }

  .arrowLeft {
    margin: 12px 50px 0 40px;
  }

  .arrowRight {
    margin-top: 12px;
  }

  img {
    width: 10px;
    cursor: pointer;
  }
}

:global {
  .scroll {
    .modal-body {
      max-height: 800px;
      overflow: scroll;
      .error-field label {
        color: red;
      }
    }
  }

  .modal-edit-contract-infor-employee {
    .modal-body {
      padding: 0 20px;
    }
    .titleMilestones {
      color: $color-navy;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .swiper-wrapper {
    align-items: center;
  }

  .relative {
    .modal-body {
      max-height: 800px;
      overflow: scroll;

      form {
        margin-top: 0 !important;
        padding: 0;

        label.form_label_date {
          position: absolute;
          top: -26%;
          left: 4%;
          z-index: 1;
          background: $color-white;
          font-size: 0.8rem;
        }

        .record {
          margin-bottom: 4%;

          .border-bt {
            background-color: $color-wheat;
            margin: 40px 0 20px 0;
          }

          .title-relative-other {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $color-black;
          }
        }
      }
    }

    .modal-body::-webkit-scrollbar {
      width: 2px;
    }

    .modal-body.thin::-webkit-scrollbar {
      width: 2px;
    }

    .modal-body::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $bg-yellow1;
    }
  }

  .edit-infor-paper {
    .MuiPaper-root {
      min-width: 1000px !important;
    }

    .modal-body {
      padding: 0;
      margin-top: 0 !important;
      overflow-x: hidden;

      .img-cccd {
        display: flex;
        margin-top: 30px;
      }

      label.form_label_date {
        position: absolute;
        top: -15%;
        left: 8%;
        z-index: 1;
        background: $bg-white;
        font-size: 0.8rem;
      }

      .title {
        background: $color-headTable;
        min-height: 65px;

        p {
          padding-top: 16px;
          padding-left: 16px;
          color: $color-navActive;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .head-modal {
        margin: 25px 0 0 0;
        padding-bottom: 15px;
        width: 96%;
        border-bottom: 2px solid $color-inputColor;

        .button-save {
          padding: 0;
        }

        .notification {
          color: $color-silver;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: center;
          margin-top: 8px;
        }
      }

      .input-modal-document {
        margin-right: 0;

        .error {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      .title-img-cccd {
        color: $color-navy;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 20px;
      }

      .react-tabs__tab-list {
        background: $color-headTable;
        margin: 0;
        list-style-type: none;
        padding-left: 0;

        .react-tabs__tab {
          cursor: pointer;
          min-height: 65px;

          .hr-border-bottom {
            margin: 20px 0 0 14px;
            width: 90%;
          }

          p {
            padding-top: 20px;
            padding-left: 22px;
            color: $color-black;
            font-size: 16px;
            line-height: 24px;
          }
        }

        .react-tabs__tab--selected {
          background: $color-reacTab;
          appearance: none !important;
          background-image: url('./../../../assets/img/icons/checked.png') !important;
          background-repeat: no-repeat !important;
          background-position-x: 90% !important;
          background-position-y: 20px !important;
          background-size: 28px 20px !important;
        }
      }
    }

    img {
      // width: 100%;
      // height: 100%;
      object-fit: cover;
    }

    .boxImages {
      height: 150px;
    }
  }
}

.btnDeleted {
  padding: 0px 3px !important;
}

.certificationBoxNew {
  padding: 0 !important;
  height: 86px !important;
  overflow: hidden;

  .rightCerti {
    .profileContentInfo {
      &:first-child {
        white-space: nowrap;
        overflow: hidden;
        width: 26rem;
      }
    }
  }

  .imgCertificate {
    height: 86px !important;
    width: 155px !important;
    overflow: hidden;

    img {
      object-fit: cover;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .profileContentInfo {
    line-height: 20px !important;
    font-size: 12px;
    padding-left: 15px !important;

    &:first-child {
      margin-top: 10px;
      line-height: 22px !important;
      font-size: 14px !important;
    }
  }
}

@media (min-width: 1400px) {
  .certificationBoxNew {
    height: 100px !important;

    .imgCertificate {
      width: 180px !important;
      height: 100px !important;
    }

    .profileContentInfo {
      font-size: 14px;
      margin-bottom: 5px;

      &:first-child {
        font-size: 16px !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .titleBox {
    height: 35px !important;
    white-space: initial;
  }

  .certificationBoxNew {
    .imgCertificate {
      width: 125px !important;
    }

    .profileContentInfo {
      font-size: 12px !important;
    }
  }

  .containerRoot {
    .rowInfo {
      .profileTitle {
        font-size: 16px;
      }
    }
  }

  .salaryBoxParent {
    .certificationBox {
      .profileContentInfo {
        &:first-child {
          font-size: 14px !important;
        }
      }
    }
  }
}

.colorSilver {
  svg {
    color: $color-silver !important;
  }
}

.boxImagesCCCD {
  position: relative;
  top: 0;
  left: 0;

  .iconImageDownload {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 2px;
    background-color: $color-button;
    border: 1px solid $color-wheat;
    position: absolute;
    top: 20px;
    right: 10px;
    text-align: center;
    line-height: 17px !important;

    img {
      border: none !important;
      border: 0;
      border-radius: 0 !important;
      width: 14px !important;
      height: 12px !important;
      object-fit: cover;
    }

    i {
      left: -2px;
      top: 3px;
    }

    &:hover {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
      cursor: pointer;
    }
  }
}

.hrListImage {
  @media (min-width: 1200px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.achievementNullCenter {
  text-align: center;

  .editDeleteButton {
    float: none !important;
    display: inline-block;
    margin-top: 3rem !important;
    width: 40px !important;
    height: 41px !important;
    border: 1.5px dashed $color-wheat !important;
    line-height: 30px !important;
  }

  .profileContentInfoAlert {
    padding-top: 15px !important;
    color: $color-silver !important;
    padding-bottom: 20px !important;
    font-weight: 400;
  }
}

.modalCommonPerview {
  position: relative;
  top: 0;
  left: 0;

  :global {
    .modal-header {
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 1;
      border: none;
    }

    .modal-body {
      max-height: inherit;
      p {
        margin-bottom: 5px;
      }
    }

    .close {
      span {
        height: 25px;
        width: 25px;
        border: 1px solid $color-charcoal;
        box-sizing: border-box;
        text-align: center;
        padding-top: 4px;
        padding-left: 1px;
        line-height: 13px;
        border-radius: 50%;
        display: inline-block;
      }
    }

    .modal-body {
      padding: 0;
    }
  }

  .img {
    // max-height: 500px;
    overflow: hidden;

    img {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      width: 100%;
      object-fit: cover;
    }
  }

  .content {
    padding: 20px;

    .title {
      font-size: 14px;
      line-height: 22px;
      color: $color-charcoal;
    }

    .time {
      font-size: 12px;
      line-height: 20px;
      color: $color-silver;
      margin-bottom: 2px;
    }
  }
}

.imgRelative {
  width: 50px;
  padding: 15px;
}

.addRelative {
  border: 1px dashed $color-wheat;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  text-align: center;
  padding-left: 14px;
}

.removeRelative {
  width: -webkit-fill-available;
  border: 1px dashed $color-red;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.btnAddCCCD {
  width: 33.5%;

  .editDeleteButton {
    margin-top: 1rem !important;
  }
}

.navMenuProfile {
  margin-top: 10px;
}

// ================================================================================================

.styleInfoMui {
  padding-left: 16px;
  padding-top: 16px;
  font-size: 14px;
  color: $color-charcoal;

  .col1InfoLeft {
    padding-left: 25px;
    font-weight: 500;
    float: left;
    width: 35%;
    height: 100%;
  }
  .col2InfoLeft {
    float: left;
    font-weight: 500;
    width: 40%;
    height: 100%;
  }
  .styleBackground {
    background-color: $bg-rowTable;
  }
  :global {
    .MuiGrid-item {
      padding-bottom: 15px;
    }
  }
  @media (max-width: 1200px) {
    .col2InfoLeft {
      padding-left: 25px;
      width: 35%;
    }
    .styleBackground {
      background-color: $bg-white;
    }
    :global {
      .MuiGrid-item:nth-child(even) {
        background-color: $bg-rowTable;
      }
    }
  }
}

.tableCell {
  display: table-cell;
}
.checkboxCustom {
  padding-top: 10px;
}

.editDeleteButtonChild {
  padding: 3px;
  color: $color-truncate;
  border-radius: 24px;
  background-color: $bg-body;
  float: right;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-left: 10px;

  svg {
    font-size: 18px;
  }
}

.editDeleteButtonChild:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.formInput {
  border: 1px solid #eee;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  padding: 0 12px;
  transition: 0.25s ease;
}

.formInput:focus,
.formInput:hover {
  border-color: $color-green;
}

.fieldError {
  border: 1px solid red;
}

.fieldError:focus,
.fieldError:hover {
  border-color: red;
}

.formField {
  position: relative;
  &:hover {
    label {
      color: $color-green;
    }
  }
}

.formField {
  position: relative;
  :global {
    .react-datepicker-popper {
      z-index: 10;
    }
    .react-datepicker-popper[data-placement^='bottom'],
    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
      left: -15px;
    }
    .react-datepicker-popper[data-placement^='bottom'],
    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
      left: -15px;
    }
  }
}

.formLabel {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
  user-select: none;
  color: #999;
  pointer-events: none;
  transition: 0.25s ease;
}
.formInput:focus,
.focus-visible:focus:not(:focus-visible) {
  outline: 0;
}

.formInput:focus + .formLabel {
  top: 0;
  left: 11px;
  padding: 0 10px;
  display: inline-block;
  background-color: $color-white;
  color: $color-green;
}

.labelError {
  color: red !important;
}

.inputFocusCustom {
  top: 0;
  left: 11px;
  font-size: 12px;
  padding: 0 10px;
  display: inline-block;
  background-color: $color-white;
  &:focus {
    color: $color-green;
  }
  &:hover {
    color: $color-green;
  }
}
.errorBirthday {
  margin-left: 10px;
  margin-top: 5px;
}
.rotation {
  label {
    margin-top: 10px;
  }
}
.pointActive {
  background-color: #2ecc71 !important;
}
.salaryBoxActive {
  background-color: #d5f5e3 !important;
}
.modalContent {
  width: 100%;
  .imgModal {
    width: 100%;
    max-height: 500px;
  }
  .imgDetail {
    padding: 20px;
    .name {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #333333;
    }
    .updateDate {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #8d8d8d;
    }
  }
}
.buttonAsLink {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
