@import './../../styles/variable.scss';
.boxImage {
  display: flex;
  flex-wrap: wrap;

  .boxItemImage {
    margin: 8px;
  }
}

.uploadImages {
  position: relative;
  left: 0;
  top: 0;
  width: 145px;
  height: 100px;

  .deleteImage {
    position: absolute;
    right: -2px;
    z-index: 5;
    top: -8px;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
    background: $bg-menuNumberInfo;
    border-radius: 50%;
    color: $color-white;
    width: 20px;
    height: 20px;
    line-height: 20px;
    opacity: 0;
  }
  &:hover {
    .deleteImage {
      opacity: 1;
      transition: opacity 0.3s;
      transition-property: opacity;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.51, 0.21, 0.35, 0.64);
      transition-delay: 0s;
    }
  }

  .buttonFile {
    border: 1px dashed $color-grey1;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
    width: 145px;
    height: 100px;
    padding: 10px;
  }

  .image {
    vertical-align: middle;
    border-style: none;
    object-fit: cover;
    width: 145px;
    height: 85px;
  }

  .image {
    width: 100%;
  }

  label {
    overflow: hidden;
  }
}

.sttImage {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: $color-grey1;
  margin-top: 10px;
}

.btnAdd {
  color: $bg-yellow1;
  font-size: 40px;
}

.listBoxImage {
  .boxItemImage {
    margin: 20px 30px 20px 0px;
    position: relative;
    top: 0;
    left: 0;
  }

  .uploadImages {
    width: 205px;
    height: 130px;

    .buttonFile {
      padding: 0;
      width: 164px;
      height: 104px;
    }
    .buttonFileOld {
      border: none;
      width: 205px;
      height: 130px;
    }

    .image {
      height: 100%;
    }
  }

  .btnAdd {
    margin-left: 5px;
  }
  .sttImage {
    width: 164px;
  }
}

.groupButton {
  .btnDownload,
  .btnDeleted {
    position: absolute;
    top: 5px;
    right: 8px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 2px;
    background-color: $color-button;
    border: 1px solid $color-wheat;
    text-align: center;
    line-height: 17px !important;
    color: $color-silver;

    &:hover {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
      cursor: pointer;
    }
  }

  .btnDownload {
    right: 42px;
    img {
      border-radius: 0 !important;
      width: 14px;
      height: 12px;
    }
    i {
      left: -2px;
      top: 3px;
    }
  }
  .btnDeleted {
  }
}
