@import "./../../../../styles/variable";

.input-search {
  width: 280px;
}

.error.custom {
  margin-top: 21px;
}

.tabs {
  .MuiTab-root {
    font-size: 16px;

    &.Mui-selected {
      color: #FEA628;
    }
  }

  .MuiTabs-indicator {
    background-color: #FEA628;
  }
}