@import 'styles/variable.scss';

.container {
  padding: 10px 5px;

  .header {
    display: flex;
    justify-content: space-between;
    background-color: #FEA628;
    padding: 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .textHeader {
      color: $color-table;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .textLeft {
    color: $color-charcoal;
    font-size: 14px;
    font-weight: 400;
  }

  .textRight {
    color: $color-charcoal;
    font-size: 14px;
    font-weight: 500;
  }

  .item {
    display: list-item;
    table-layout: fixed;
    list-style-type: none;
    list-style-position: outside;
    padding: 1em 0 1em 2em;
    line-height: 1.14285714em;
    position: relative;
    background-color: #F9F9F9;
  
    .content {
      padding-right: 15px;
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
    }
  
    &::before {
      position: absolute;
      padding-left: 15px;
      top: 45%;
      left: 15px;
      width: 20px;
      height: 20px;
      margin-top: -8px;
      content: '';
      background-image: url('./../../../../../assets/img/icons/clock-history.svg');
      background-repeat: no-repeat;
      z-index: 1;
    }
  
    &::after {
      position: absolute;
      left: 24px;
      width: 0px;
      height: 100%;
      content: '';
      border-right: 2px solid #858585;
    }

    &:last-child::after {
      top: 0;
      position: absolute;
      height: 40%;
    }
  }

  .onlyItem {
    display: list-item;
    table-layout: fixed;
    list-style-type: none;
    list-style-position: outside;
    padding: 1em 0 1em 2em;
    line-height: 1.14285714em;
    position: relative;
    background-color: #F9F9F9;
  
    .content {
      padding-right: 15px;
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
    }
  
    &::before {
      position: absolute;
      padding-left: 15px;
      top: 45%;
      left: 15px;
      width: 20px;
      height: 20px;
      margin-top: -8px;
      content: '';
      background-image: url('./../../../../../assets/img/icons/clock-history.svg');
      background-repeat: no-repeat;
      z-index: 1;
    }
  }
}
