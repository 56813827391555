@import "./../../../../styles/variable";

.input-search {
  width: 280px;
}

.master-data-create-filter {
  margin-bottom: 100px;
  .infor-on-table {
    display: flex;
    .align-items-center {
      position: absolute;
      right: 20px;
      background: $bg-yellow2;
      border-radius: 5px;
      color: $bg-yellow1;
    }
  }
  .save {
    width: 85px;
    float: right;
  }
  .loading-custom {
    margin-top: 200px !important;
  }
  a {
    text-decoration: none !important;
  }
  margin-top: 20px;
  .title-list-member {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $color-navActive;
  }
  .table-list {
    margin-top: 12px;
    box-shadow: 0px 5px 25px $color-box-shadow;
    color: $color-black;
    background: $color-table;
    box-sizing: border-box !important;
    border-radius: 5px;
    border: 2px solid $color-boder-table;
    max-height: 600px;
    tr.header {
      position: sticky;
      top: 0;
      height: 40px;
      z-index: 1;
    }
    th {
      vertical-align: bottom;
      border-bottom: 2px solid $color-boder-table;
    }
    td {
      padding: 16px;
      border-bottom: none;
    }
    td.error-no-data {
      text-align: center;
      color: $color-red;
    }
    .css-1nr2wod {
      color: $color-switch-base !important;
    }
    .css-1nr2wod.Mui-checked + .MuiSwitch-track {
      background-color: $color-muiswitch-track !important;
    }
  }
  .table-list::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .table-list.thin::-webkit-scrollbar {
    width: 2px;
  }
  .table-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-boder-table;
  }
  .table-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $bg-yellow1;
  }
  .table-list::-webkit-scrollbar-track-piece:start {
    margin-top: 40px;
    background: $bg-white;
  }

  .filter {
    background: $color-table;
    border: 1px solid $color-borderTable;
    box-sizing: border-box;
    box-shadow: 0px 5px 25px $color-box-shadow;
    border-radius: 5px;
    padding: 20px;
    .error.images {
      margin-top: 30px;
      text-align: center;
    }
    .field-name {
      margin-top: 55px !important;
      margin-bottom: 1.5rem !important;
    }
    .division {
      margin-bottom: 1.5rem !important;
    }
    h1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $color-navy;
    }
  }
}
.master-data-create-group-account {
  a {
    text-decoration: none !important;
  }
  .bread-crumbs {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    .page-current {
      color: $color-navy;
      font-weight: 500;
    }
    a {
      color: $color-silver;
    }
  }
  img.radio {
    width: 20px;
    cursor: pointer;
  }
  .filter-custom {
    margin-top: 50px;
    display: flex;
    span {
      cursor: pointer;
      margin-left: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $color-titleExport;
    }
    span.create-filter {
      margin-right: 100px;
    }
  }
}
.master-data-group-access {
  .sidebar {
    padding-right: 10px;
  }
  .table-list {
    margin-bottom: 100px;
    margin-top: 55px;
    box-shadow: 0px 5px 25px $color-box-shadow;
    color: $color-black;
    background: $color-table;
    box-sizing: border-box !important;
    border-radius: 5px;
    border: 2px solid $color-boder-table;
    th {
      vertical-align: bottom;
      border-bottom: 2px solid $color-boder-table;
    }
    td {
      padding: 16px;
      border-bottom: none;
      a {
        text-decoration: none !important;
        color: $color-nav !important;
      }
    }
    td.error-no-data {
      text-align: center;
      color: $color-red;
    }
  }
}

// Pop up
.no-padding-modal-body {
  .MuiPaper-root {
    min-width: 800px !important;
  }
  .modal-body {
    overflow-y: unset;
  }
}
.popup-add-member-custom {
  .list-member-custom {
    min-height: 325px;
    .select-all-option {
      color: $color-buttonSubmit;
      &:hover {
        cursor: pointer;
      }
    }

    .not-select-all-option {
      color: $color-grey1;
      &:hover {
        cursor: pointer;
      }
    }

    .disable-checkbox {
      .MuiSvgIcon-root {
        display: none;
      }
    }

    .list-member-checkbox {
      margin: 0 -24px;
      overflow: auto;
      max-height: 300px;
      .error {
        text-align: center;
      }
      .MuiListItemButton-root {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 24px;

        .MuiListItemText-root {
          margin: 0;
          border-top: 1px solid $color-border-input;
        }
      }

      .selected-item {
        background-color: $color-active-selected;
      }

      .item-selected {
        &:last-child {
          .MuiListItemText-root {
            border-bottom: 1px solid $color-border-input;
          }
        }
        padding: 0;
        margin: 1px 0;
        height: 100%;
        min-height: 53px;
        display: flex;
        align-items: center;
        label {
          margin: 0;
          width: 100%;
          min-height: 53px;
          flex-direction: row-reverse;
          justify-content: space-between;
        }

        .info-wrapper {
          .avatar {
            section {
              width: 40px;
              height: 40px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }

          .bottom-content {
            font-size: 14px;
            color: $color-silver;
          }
        }
      }
    }
  }
}
