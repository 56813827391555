@import 'styles/variable.scss';

.linkRequest {
  color: $color-black;
  text-decoration: none !important;

  &:hover {
    color: $color-black;
    text-decoration: none !important;
  }
}

.sendMailSelectedItems {
  padding: 8px;
  margin-left: 10px;
  width: 170px;

  svg {
    transform: rotate(90deg);
    margin-bottom: 4px;
  }
}

.checkboxAll {
  width: 40px;
}

table {
  th,td {
    max-width: 200px;
    vertical-align: middle;
    color: $color-navActive;
    .checked {
      cursor: pointer;
      margin: 0;
      margin-top: -8px;
      background-color: $color-white;
      font: caption;
      color: currentColor;
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid $color-silver;
      border-radius: 0.15em;
      transform: translateY(-0.075em);
    }
  }
}
