@import './../../../../styles/variable';

label {
  font-size: 14px;
}

.rdtPicker {
  width: 260px;
}

.button-delete-box {
  padding-right: 24px !important;
}

.block-time-middle {
  vertical-align: middle;
  display: inline-block;
  line-height: 55px;
  margin-left: -15px;
}
