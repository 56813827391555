@import "./../../../../styles/variable";

.master-data-role {
  .dropBox {
    width: 200px;
  }

  .select-position {
    height: 35px !important;
    border-width: 0 !important;
  }

  .profileContentInfoAlert {
    color: $color-navActive;
    line-height: 2rem;
    font-size: 1.1rem;
    padding: 40px;
    margin-left: 40%;
  }
  .edit-link {
    color: #8d8d8d !important;
  }
}

.modal-add-role-master-data {
  .title-role {
    color: $color-navActive;
    margin: 20px 0 0 0;
  }
  .permissions {
    padding-left: 26px;
    background: #f9fbfc;
    padding-top: 20px;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 5px;
  }
}
