@import "./../../styles/variable.scss";
.inforTotal {
  color: $color-nav;
  font-size: 14px;
}
.arrowPage {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .dropPerPage {
    display: flex;
    .textRowPerPage {
      margin: 6px 4px 0 0;
      color: #8d8d8d;
    }
    .perpage {
      font-size: 14px;
      cursor: pointer;
      border: none;
      color: $color-nav;
      width: 65px;
      appearance: none;
      background-image: url("./../../assets/img/icons/icon-down.png");
      background-repeat: no-repeat;
      background-position-x: 96% !important;
      background-position-y: 14px !important;
      background-size: 13px 8px;
      &:focus {
        color: $color-perPage !important;
        appearance: none !important;
        background-image: url("./../../assets/img/icons/icon-down.png") !important;
        background-repeat: no-repeat !important;
        background-position-x: 96% !important;
        background-position-y: 14px !important;
        background-size: 13px 8px !important;
      }
    }
    .custom {
      background-color: $bg-tableStripped;
      &:focus {
        background: $bg-tableStripped !important;
        color: $color-perPage !important;
        appearance: none !important;
        background-image: url("./../../assets/img/icons/icon-down.png") !important;
        background-repeat: no-repeat !important;
        background-position-x: 96% !important;
        background-position-y: 18px !important;
        background-size: 13px 8px !important;
      }
    }
  }
  .arrowLeft {
    margin: 2% 50px 0 40px;
  }
  .arrowRight {
    margin-top: 2%;
  }
  img {
    width: 10px;
    cursor: pointer;
  }
}
