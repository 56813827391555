@import "styles/variable.scss";

.export-import-inventory {
  .scroll-custom {
    .sum-header {
      top: 64px;
    }
  }

  table {
    width: 100% !important;

    .asset-header {
      td {
        word-break: break-all;
      }
      th {
        width: 100px;
        border-bottom: 0;
        background-color: $bg-salmon !important;
      }
    }
  }
  @media only screen and (max-width: 1220px) {
    .table-scroll {
      width: 120% !important;
    }
  }

  .box-waiting {
    padding: 7px 15px 0 15px;

    .box-waiting-content {
      padding: 0;
      padding: 10px 0 10px 0;
      border-bottom: 1px solid $color-wheat;

      &:last-child {
        border-bottom: none;
      }

      img {
        width: 17px !important;
        height: 17px !important;
      }
    }
  }

  .added-padding {
    padding-bottom: 10px !important;
  }

  .breakWord{
    word-break: break-all;
  }
}
