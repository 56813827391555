@import 'styles/variable.scss';
.modal-add-assets {
  .modal-body {
    padding: 30px 20px 0 20px;

    form {
      .field-form {
        margin: 20px 0;
      }

      .field-added-placeholder {
        position: relative;
        input {
          padding-right: 16%;
        }
        .placeholder-added {
          font-size: 16px;
          position: absolute;
          right: 19px;
          color: $color-grey1;
          top: 14px;
        }
      }

      .btn-submit {
        margin-top: 4px;
      }
    }
  }
}

.modal-detail-assets {
  .modal-body {
    padding: 0 17px 27px 17px;
    overflow-y: unset;
    .assets-name-wrapper {
      margin: 0 -17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 22px 20px;
      background-color: $bg-salmon;
      height: 67px;
      margin-bottom: 20px;
      color: $color-navy;
      font-weight: bold;
    }
    .assets-name {
      text-overflow: ellipsis;
      overflow: hidden; 
    }

    .assets-detail {
      margin-bottom: 30px;
      .assets-detail-item {
        border-bottom: 1px solid $color-wheat;
        margin-right: 3%;
        margin-top: 9px;
        .assets-detail-first {
          color: $color-navy;
          font-weight: bold;
        }
        .assets-detail-second {
          color: $color-charcoal;
          margin-bottom: 12px;
          word-break: break-all;
        }
      }
    }

    .assets-history {
      padding: 10px 10px 0 10px;
      border: 1px solid $color-wheat;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .assets-history-name {
        color: $color-navy;
        font-weight: bold;
      }

      .assets-history-table {
        margin: 10px -10px 0 -10px;
        height: 268px;

        .table-list {
          height: 100%;
          overflow-y: auto;
          border: none;

          thead {
            position: sticky;
            top: 0;
          }
          th,
          td {
            padding: 17px 16px;
          }

          td {
            .email-admin {
              font-size: 12px;
              color: $color-silver;
            }
          }
        }
      }
    }
  }

  @media (min-width: 576px) {
    .MuiPaper-root {
      min-width: 1102px;
    }
  }
}
