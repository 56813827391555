@import "styles/variable.scss";

.linkRequest {
  color: $color-black;
  text-decoration: none !important;
  &:hover {
    color: $color-black;
    text-decoration: none !important;
  }
}
