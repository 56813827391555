@import 'styles/variable.scss';
.navbar {
  display: flex;
  list-style: none;
  padding-left: 10px;
  margin-bottom: 30px;
  padding-top: 5px;

  .link {
    color: $color-nav;
    font-size: 17px;
    margin-right: 10px;
    line-height: 22px;
    padding-bottom: 10px;
    text-transform: uppercase;
    text-decoration: none;
    .navItem {
      padding: 0 10px;
      text-transform: uppercase;
    }
  }
  .isActive {
    color: $color-navActive;
    border-bottom: 2px solid $color-menuActive;
  }
}
.containerRoot {
  margin: auto;
  width: 100%;
  background: $bg-body;
  overflow-x: hidden;
  z-index: -1;
  margin-bottom: 8px;
  color: $color-nav;
  .linkBread {
    display: flex;
    margin-left: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    .pageCurrent {
      font-weight: 500;
      color: $color-navActive !important;
    }
    a {
      text-decoration: none;
      color: $color-nav;
    }
  }
}
